import React, { Component } from "react";
import "./Formateur.css";

class Formateur extends Component {
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="ContainerStatistiques">
        <div className="PodiumContainer">
          <div className="titreStats">
            <div>Podium</div>
          </div>

          <div className="corpsPodium">
            <table>
              <tr>
                <th>Firstname and Lastname</th>
                <th>Scores</th>
              </tr>
              <tr>
                <td>User 1</td>
                <td>37</td>
              </tr>

              <tr>
                <td>User 2</td>
                <td>33</td>
              </tr>

              <tr>
                <td>User 2</td>
                <td>30</td>
              </tr>

              <tr>
                <td>User 3</td>
                <td>28</td>
              </tr>

              <tr>
                <td>User 4</td>
                <td>25</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Formateur;
