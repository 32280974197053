import React, { Component } from "react";
import "./Q5.css";
import axios from "axios";

class Q5 extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      Q5: "",
      etat: 5,
      ra: "",
      rb: "",
      rc: "",
      rd1: "",
      rd2: "",
      re: "",
      probleme: "",

      a0: false,
      a1: false,
      a2: false,
      a3: false,
      a4: false,
      a4: false,
      a5: false,
      a6: false,
      a7: false,
      a8: false,
      a9: false,
      a10: false,

      b0: false,
      b1: false,
      b2: false,
      b3: false,
      b4: false,
      b4: false,
      b5: false,
      b6: false,
      b7: false,
      b8: false,
      b9: false,
      b10: false,

      c1: false,
      c2: false,
      c3: false,
      c4: false,

      d11: false,
      d12: false,

      d21: false,
      d22: false,

      e1: false,
      e2: false,
      e3: false,
      e4: false,

      GOOD: false,
      BAD: false,

      email_invite: "",
    };
  }
  componentWillMount() {}
  componentDidMount() {
    this.getUserbyToken();
  }
  getUserbyToken = () => {
    setTimeout(() => {
      axios
        .post(
          `https://quizfnim.henri8.com/api/users/getUserByToken`,
          {
            token: this.props.token,
          } /*,
          {
            headers: {
              "Access-Control-Request-Headers":
                "X-Requested-With,X-Auth-Token,Content-Type,  Content-Length, Authorization",
            },
          }*/
        )
        .then((res) => {
          this.setState({
            email_invite: res.data.email_invite,
          });
        })
        .catch((err) => console.log(err));
    }, 200);
  };

  //RA

  handleChangeRA0 = () => {
    this.setState({ ra: "0" });
    this.setState({ a0: true });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA1 = () => {
    this.setState({ ra: "1" });
    this.setState({ a0: false });
    this.setState({ a1: true });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA2 = () => {
    this.setState({ ra: "2" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: true });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA3 = () => {
    this.setState({ ra: "3" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: true });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA4 = () => {
    this.setState({ ra: "4" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: true });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA5 = () => {
    this.setState({ ra: "5" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: true });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA6 = () => {
    this.setState({ ra: "6" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: true });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA7 = () => {
    this.setState({ ra: "7" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: true });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA8 = () => {
    this.setState({ ra: "8" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: true });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA9 = () => {
    this.setState({ ra: "9" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: true });
    this.setState({ a10: false });
  };

  handleChangeRA10 = () => {
    this.setState({ ra: "10" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: true });
  };

  //RB

  handleChangeRB0 = () => {
    this.setState({ rb: "0" });
    this.setState({ b0: true });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: false });
    this.setState({ b5: false });
    this.setState({ b6: false });
    this.setState({ b7: false });
    this.setState({ b8: false });
    this.setState({ b9: false });
    this.setState({ b10: false });
  };

  handleChangeRB1 = () => {
    this.setState({ rb: "1" });
    this.setState({ b0: false });
    this.setState({ b1: true });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: false });
    this.setState({ b5: false });
    this.setState({ b6: false });
    this.setState({ b7: false });
    this.setState({ b8: false });
    this.setState({ b9: false });
    this.setState({ b10: false });
  };

  handleChangeRB2 = () => {
    this.setState({ rb: "2" });
    this.setState({ b0: false });
    this.setState({ b1: false });
    this.setState({ b2: true });
    this.setState({ b3: false });
    this.setState({ b4: false });
    this.setState({ b5: false });
    this.setState({ b6: false });
    this.setState({ b7: false });
    this.setState({ b8: false });
    this.setState({ b9: false });
    this.setState({ b10: false });
  };

  handleChangeRB3 = () => {
    this.setState({ rb: "3" });
    this.setState({ b0: false });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: true });
    this.setState({ b4: false });
    this.setState({ b5: false });
    this.setState({ b6: false });
    this.setState({ b7: false });
    this.setState({ b8: false });
    this.setState({ b9: false });
    this.setState({ b10: false });
  };

  handleChangeRB4 = () => {
    this.setState({ rb: "4" });
    this.setState({ b0: false });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: true });
    this.setState({ b5: false });
    this.setState({ b6: false });
    this.setState({ b7: false });
    this.setState({ b8: false });
    this.setState({ b9: false });
    this.setState({ b10: false });
  };

  handleChangeRB5 = () => {
    this.setState({ rb: "5" });
    this.setState({ b0: false });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: false });
    this.setState({ b5: true });
    this.setState({ b6: false });
    this.setState({ b7: false });
    this.setState({ b8: false });
    this.setState({ b9: false });
    this.setState({ b10: false });
  };

  handleChangeRB6 = () => {
    this.setState({ rb: "6" });
    this.setState({ b0: false });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: false });
    this.setState({ b5: false });
    this.setState({ b6: true });
    this.setState({ b7: false });
    this.setState({ b8: false });
    this.setState({ b9: false });
    this.setState({ b10: false });
  };

  handleChangeRB7 = () => {
    this.setState({ rb: "7" });
    this.setState({ b0: false });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: false });
    this.setState({ b5: false });
    this.setState({ b6: false });
    this.setState({ b7: true });
    this.setState({ b8: false });
    this.setState({ b9: false });
    this.setState({ b10: false });
  };

  handleChangeRB8 = () => {
    this.setState({ rb: "8" });
    this.setState({ b0: false });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: false });
    this.setState({ b5: false });
    this.setState({ b6: false });
    this.setState({ b7: false });
    this.setState({ b8: true });
    this.setState({ b9: false });
    this.setState({ b10: false });
  };

  handleChangeRB9 = () => {
    this.setState({ rb: "9" });
    this.setState({ b0: false });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: false });
    this.setState({ b5: false });
    this.setState({ b6: false });
    this.setState({ b7: false });
    this.setState({ b8: false });
    this.setState({ b9: true });
    this.setState({ b10: false });
  };

  handleChangeRB10 = () => {
    this.setState({ rb: "10" });
    this.setState({ b0: false });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: false });
    this.setState({ b5: false });
    this.setState({ b6: false });
    this.setState({ b7: false });
    this.setState({ b8: false });
    this.setState({ b9: false });
    this.setState({ b10: true });
  };

  // RC
  handleChangeRC1 = () => {
    this.setState({ rc: "Non" });
    this.setState({ c1: true });
    this.setState({ c2: false });
    this.setState({ c3: false });
    this.setState({ c4: false });
  };

  handleChangeRC2 = () => {
    this.setState({ rc: "Rarement" });
    this.setState({ c1: false });
    this.setState({ c2: true });
    this.setState({ c3: false });
    this.setState({ c4: false });
  };

  handleChangeRC3 = () => {
    this.setState({ rc: "Parfois" });
    this.setState({ c1: false });
    this.setState({ c2: false });
    this.setState({ c3: true });
    this.setState({ c4: false });
  };

  handleChangeRC4 = () => {
    this.setState({ rc: "Souvent" });
    this.setState({ c1: false });
    this.setState({ c2: false });
    this.setState({ c3: false });
    this.setState({ c4: true });
  };

  //RD1
  handleChangeRD11 = () => {
    this.setState({ rd1: "Non" });
    this.setState({ d11: true });
    this.setState({ d12: false });
  };

  handleChangeRD12 = () => {
    this.setState({ rd1: "Oui" });
    this.setState({ d11: false });
    this.setState({ d12: true });
  };

  //RD2
  handleChangeRD21 = () => {
    this.setState({ rd2: "Non" });
    this.setState({ d21: true });
    this.setState({ d22: false });
  };

  handleChangeRD22 = () => {
    this.setState({ rd2: "Oui" });
    this.setState({ d21: false });
    this.setState({ d22: true });
  };

  // RE
  handleChangeRE1 = () => {
    this.setState({ re: "Jamais" });
    this.setState({ e1: true });
    this.setState({ e2: false });
    this.setState({ e3: false });
    this.setState({ e4: false });
  };

  handleChangeRE2 = () => {
    this.setState({ re: "Parfois" });
    this.setState({ e1: false });
    this.setState({ e2: true });
    this.setState({ e3: false });
    this.setState({ e4: false });
  };

  handleChangeRE3 = () => {
    this.setState({ re: "Souvent" });
    this.setState({ e1: false });
    this.setState({ e2: false });
    this.setState({ e3: true });
    this.setState({ e4: false });
  };

  handleChangeRE4 = () => {
    this.setState({ re: "Toujours" });
    this.setState({ e1: false });
    this.setState({ e2: false });
    this.setState({ e3: false });
    this.setState({ e4: true });
  };

  handleChangeTextArea = (e) => {
    this.setState({ probleme: e.target.value });
  };

  handleSubmitQ5 = () => {
    if (
      (this.state.ra &&
        this.state.rb &&
        this.state.rc &&
        this.state.rd1 &&
        this.state.rd2 &&
        this.state.re) != ""
    ) {
      this.setState({ GOOD: true });

      // fonction

      axios
        .put(`https://quizfnim.henri8.com/api/users/Quiz5`, {
          email_invite: this.state.email_invite,
          Q5RA: this.state.ra,
          Q5RB: this.state.rb,
          Q5RC: this.state.rc,
          Q5RD1: this.state.rd1,
          Q5RD2: this.state.rd2,
          Q5RE: this.state.re,
          Q6R: this.state.probleme,
        })
        .then((res) => {
          setTimeout(() => {
            this.setState({ GOOD: false });
            this.props.parentCallback(this.state.etat);
          }, "3000");
        })
        .catch((err) => alert("Une erreur s'est produite"));
    } else {
      this.setState({ BAD: true });

      setTimeout(() => {
        this.setState({ BAD: false });
      }, "5000");
    }
  };

  render() {
    var alertGOO = "";
    var alertBAD = "";

    if (this.state.GOOD == true) {
      alertGOO = (
        <div className="ContainerAlerte">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Vos réponses ont bien été prise en compte
          </div>
        </div>
      );
    }

    if (this.state.BAD == true) {
      alertBAD = (
        <div className="ContainerAlerteBad">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Veuillez répondre à toutes les questions avant de valider
          </div>
        </div>
      );
    }

    return (
      <div className="ContainerQ">
        <div className="TitreQuestion">5. Relations avec les achats</div>
        <div className="sousTitre">
          {" "}
          a. Qu’apportent, selon-vous, les services Achats au business ? 0
          (frein au business) - 10 (facilitation du business)
        </div>
        <div className="itemQ">
          <div
            onClick={this.handleChangeRA0}
            className={
              this.state.a0 ? "vert button  Right" : "button3 button  Right"
            }
          >
            0{" "}
          </div>
          <div
            onClick={this.handleChangeRA1}
            className={this.state.a1 ? "vert button  " : "button3 button  "}
          >
            {" "}
            1{" "}
          </div>
          <div
            onClick={this.handleChangeRA2}
            className={this.state.a2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            2{" "}
          </div>
          <div
            onClick={this.handleChangeRA3}
            className={this.state.a3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            3{" "}
          </div>
          <div
            onClick={this.handleChangeRA4}
            className={this.state.a4 ? "vert button  " : "button3 button  "}
          >
            {" "}
            4{" "}
          </div>
          <div
            onClick={this.handleChangeRA5}
            className={this.state.a5 ? "vert button  " : "button3 button  "}
          >
            {" "}
            5{" "}
          </div>
          <div
            onClick={this.handleChangeRA6}
            className={this.state.a6 ? "vert button  " : "button3 button  "}
          >
            {" "}
            6{" "}
          </div>
          <div
            onClick={this.handleChangeRA7}
            className={this.state.a7 ? "vert button  " : "button3 button  "}
          >
            {" "}
            7{" "}
          </div>
          <div
            onClick={this.handleChangeRA8}
            className={this.state.a8 ? "vert button  " : "button3 button  "}
          >
            {" "}
            8{" "}
          </div>
          <div
            onClick={this.handleChangeRA9}
            className={this.state.a9 ? "vert button  " : "button3 button  "}
          >
            {" "}
            9{" "}
          </div>
          <div
            onClick={this.handleChangeRA10}
            className={this.state.a10 ? "vert button  " : "button3 button  "}
          >
            {" "}
            10{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          b. Est-ce que les services Achats simplifient les négociations ? 0
          (pas du tout d’accord) - 10 (tout à fait d’accord)
        </div>
        <div className="itemQ">
          <div
            onClick={this.handleChangeRB0}
            className={
              this.state.b0 ? "vert button  Right" : "button3 button  Right"
            }
          >
            0{" "}
          </div>
          <div
            onClick={this.handleChangeRB1}
            className={this.state.b1 ? "vert button  " : "button3 button  "}
          >
            {" "}
            1{" "}
          </div>
          <div
            onClick={this.handleChangeRB2}
            className={this.state.b2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            2{" "}
          </div>
          <div
            onClick={this.handleChangeRB3}
            className={this.state.b3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            3{" "}
          </div>
          <div
            onClick={this.handleChangeRB4}
            className={this.state.b4 ? "vert button  " : "button3 button  "}
          >
            {" "}
            4{" "}
          </div>
          <div
            onClick={this.handleChangeRB5}
            className={this.state.b5 ? "vert button  " : "button3 button  "}
          >
            {" "}
            5{" "}
          </div>
          <div
            onClick={this.handleChangeRB6}
            className={this.state.b6 ? "vert button  " : "button3 button  "}
          >
            {" "}
            6{" "}
          </div>
          <div
            onClick={this.handleChangeRB7}
            className={this.state.b7 ? "vert button  " : "button3 button  "}
          >
            {" "}
            7{" "}
          </div>
          <div
            onClick={this.handleChangeRB8}
            className={this.state.b8 ? "vert button  " : "button3 button  "}
          >
            {" "}
            8{" "}
          </div>
          <div
            onClick={this.handleChangeRB9}
            className={this.state.b9 ? "vert button  " : "button3 button  "}
          >
            {" "}
            9{" "}
          </div>
          <div
            onClick={this.handleChangeRB10}
            className={this.state.b10 ? "vert button  " : "button3 button  "}
          >
            {" "}
            10{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          c. Avez-vous déjà eu à faire des enchères inversées pour faire baisser
          les prix ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRC1}
            className={this.state.c1 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Non{" "}
          </div>
          <div
            onClick={this.handleChangeRC2}
            className={this.state.c2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Rarement{" "}
          </div>
          <div
            onClick={this.handleChangeRC3}
            className={this.state.c3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Parfois{" "}
          </div>
          <div
            onClick={this.handleChangeRC4}
            className={this.state.c4 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Souvent{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          d/1. Ces enchères inversées vous semblent-elles justifiées lorsque le
          volume de business est important ?
        </div>
        <div className="itemQ">
          <div
            onClick={this.handleChangeRD11}
            className={this.state.d11 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Oui{" "}
          </div>
          <div
            onClick={this.handleChangeRD12}
            className={this.state.d12 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Non{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          d/2. Vous est-il arrivé de devoir rétrocéder de l’argent à la fin de
          l’année ou à la fin du contrat, au Laboratoire ?
        </div>
        <div className="itemQ">
          <div
            onClick={this.handleChangeRD21}
            className={this.state.d21 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Oui{" "}
          </div>
          <div
            onClick={this.handleChangeRD22}
            className={this.state.d22 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Non{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          e. Constatez-vous des délais de paiement conformes à la loi : 60 jours
          maximum ?
        </div>
        <div className="itemQ">
          <div
            onClick={this.handleChangeRE1}
            className={this.state.e1 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Jamais{" "}
          </div>
          <div
            onClick={this.handleChangeRE2}
            className={this.state.e2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Parfois{" "}
          </div>
          <div
            onClick={this.handleChangeRE3}
            className={this.state.e3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Souvent
          </div>
          <div
            onClick={this.handleChangeRE4}
            className={this.state.e4 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Toujours{" "}
          </div>
        </div>

        <div className="TitreQuestion">
          6. Problèmes que nous n’avons pas soulevés
        </div>

        <div className="itemQ">
          <textarea
            value={this.state.probleme}
            onChange={this.handleChangeTextArea}
          ></textarea>
        </div>

        <div className="containerBtnValider">
          <div className="button button10" onClick={this.handleSubmitQ5}>
            {" "}
            Valider
          </div>
        </div>
        {alertGOO}
        {alertBAD}
      </div>
    );
  }
}

export default Q5;
