import React, { Component } from "react";
import "./Global.css";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";

class Global extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      sommaire: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillMount() {}
  componentDidMount() {}

  handleSubmit(event) {
    this.props.history.push("/Stats/pays");
    event.preventDefault();
  }

  render() {
    return (
      <div className="ContainerStatistiques">
        <div className="titreStats">
          <div> Global statistics</div>
        </div>

        <div className="container">
          <div className="containerBar">
            <div className="logoPays" onClick={this.handleSubmit}>
              France
            </div>
            <div class="progressbar-wrapper">
              <div title="downloading" className="progressbar A"></div>
            </div>
            <div className="PointPays"> 100 points</div>
          </div>

          <div className="containerBar">
            <div className="logoPays" onClick={this.handleSubmit}>
              Espagne
            </div>
            <div class="progressbar-wrapper">
              <div title="downloading" className="progressbar B"></div>
            </div>
            <div className="PointPays"> 90 points</div>
          </div>

          <div className="containerBar">
            <div className="logoPays" onClick={this.handleSubmit}>
              Italy
            </div>
            <div class="progressbar-wrapper">
              <div title="downloading" className="progressbar C"></div>
            </div>
            <div className="PointPays"> 80 points</div>
          </div>

          <div className="containerBar">
            <div className="logoPays" onClick={this.handleSubmit}>
              Australia
            </div>
            <div class="progressbar-wrapper">
              <div title="downloading" className="progressbar D"></div>
            </div>
            <div className="PointPays"> 50 points</div>
          </div>

          <div className="containerBar">
            <div className="logoPays" onClick={this.handleSubmit}>
              USA
            </div>
            <div class="progressbar-wrapper">
              <div title="downloading" className="progressbar E"></div>
            </div>
            <div className="PointPays"> 47 points</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Global;
