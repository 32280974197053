import React, { Component } from "react";
import "./QuizUtilisateur.css";
import { Firebase_Config } from "../../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";

class QuizUtilisateur extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      page: 0,
      R1: false,
      R2: false,
      R3: false,

      Q2R1: false,
      Q2R2: false,
      Q2R3: false,

      Q3R1: false,
      Q3R2: false,
      Q3R3: false,

      fullname: "",
      voted: false,
      voted2: false,
      voted3: false,
      compteurvote1: 0,
      compteurvote2: 0,
      compteurvote3: 0,
      note: 0,
    };
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleReponse1 = this.handleReponse1.bind(this);
    this.handleReponse2 = this.handleReponse2.bind(this);
    this.handleReponse3 = this.handleReponse3.bind(this);

    this.handleReponse1Q2 = this.handleReponse1Q2.bind(this);
    this.handleReponse2Q2 = this.handleReponse2Q2.bind(this);
    this.handleReponse3Q2 = this.handleReponse3Q2.bind(this);

    this.handleReponse1Q3 = this.handleReponse1Q3.bind(this);
    this.handleReponse2Q3 = this.handleReponse2Q3.bind(this);
    this.handleReponse3Q3 = this.handleReponse3Q3.bind(this);

    this.handleSubmitQuiz1 = this.handleSubmitQuiz1.bind(this);
    this.handleSubmitQuiz2 = this.handleSubmitQuiz2.bind(this);
    this.handleSubmitQuiz3 = this.handleSubmitQuiz3.bind(this);
  }
  handleChangePage(event) {
    this.setState({ page: event.target.value });
  }

  //Quiz 1
  handleReponse1(event) {
    this.setState({ R1: true, R2: false, R3: false });
    event.preventDefault();
  }
  handleReponse2(event) {
    this.setState({ R1: false, R2: true, R3: false });
    event.preventDefault();
  }
  handleReponse3(event) {
    this.setState({ R1: false, R2: false, R3: true });
    event.preventDefault();
  }

  handleSubmitQuiz1(event) {
    let ville = "";
    let score = 0;

    if (this.state.voted == false) {
      if (this.state.R1 == true) {
        ville = "Marseille";
        score = 0;
      } else if (this.state.R2 == true) {
        ville = "Lyon";
        score = 0;
      } else {
        ville = "Paris";
        score = 1;
        this.setState({ note: this.state.note + 1 });
      }

      let uid = firebase.database().ref().child("PodiumQuiz1App").push().key;

      let d = new Date();
      let n = d.toLocaleString();
      let data = {
        id: uid,
        Fullname: this.state.fullname,
        date: n,
        score: score,
        ville: ville,
      };

      let updates = {};
      let updates2 = {};

      updates["/PodiumQuiz1App/" + uid] = data;
      firebase
        .database()
        .ref()
        .update(updates)
        .then(
          this.setState({
            voted: true,
          })
        );

      updates2["/PodiumQuiz1AppSave/" + uid] = data;
      firebase.database().ref().update(updates2);
      firebase
        .database()
        .ref("compteurvote1")
        .set(this.state.compteurvote1 + 1);
      alert("your answer has been submitted");
      event.preventDefault();
    } else {
      alert("You have alredy voted");
    }
  }

  // Quiz2

  handleReponse1Q2(event) {
    this.setState({ Q2R1: true, Q2R2: false, Q2R3: false });
    event.preventDefault();
  }
  handleReponse2Q2(event) {
    this.setState({ Q2R1: false, Q2R2: true, Q2R3: false });
    event.preventDefault();
  }
  handleReponse3Q2(event) {
    this.setState({ Q2R1: false, Q2R2: false, Q2R3: true });
    event.preventDefault();
  }

  handleSubmitQuiz2(event) {
    let ville = "";
    let score = 0;

    if (this.state.voted2 == false) {
      if (this.state.Q2R1 == true) {
        ville = "Sydney";
        score = 1;
        this.setState({ note: this.state.note + 1 });
      } else if (this.state.Q2R2 == true) {
        ville = "Melbourne";
        score = 0;
      } else {
        ville = "Brisbane";
        score = 0;
      }
      let uid = firebase.database().ref().child("PodiumQuiz2App").push().key;

      let d = new Date();
      let n = d.toLocaleString();
      let data = {
        id: uid,
        Fullname: this.state.fullname,
        date: n,
        score: score,
        ville: ville,
      };

      let updates = {};
      let updates2 = {};

      updates["/PodiumQuiz2App/" + uid] = data;
      firebase
        .database()
        .ref()
        .update(updates)
        .then(
          this.setState({
            voted2: true,
          })
        );

      updates2["/PodiumQuiz2AppSave/" + uid] = data;
      firebase.database().ref().update(updates2);
      firebase
        .database()
        .ref("compteurvote2")
        .set(this.state.compteurvote2 + 1);

      alert("your answer has been submitted");
    } else {
      alert("You have alredy voted");
    }
  }

  //quiz 3
  handleReponse1Q3(event) {
    this.setState({ Q3R1: true, Q3R2: false, Q3R3: false });
    event.preventDefault();
  }
  handleReponse2Q3(event) {
    this.setState({ Q3R1: false, Q3R2: true, Q3R3: false });
    event.preventDefault();
  }
  handleReponse3Q3(event) {
    this.setState({ Q3R1: false, Q3R2: false, Q3R3: true });
    event.preventDefault();
  }

  handleSubmitQuiz3(event) {
    let ville = "";
    let score = 0;

    if (this.state.voted3 == false) {
      if (this.state.Q3R1 == true) {
        ville = "Rome";
        score = 1;
        this.setState({ note: this.state.note + 1 });
      } else if (this.state.Q3R2 == true) {
        ville = "Milan";
        score = 0;
      } else {
        ville = "Parma";
        score = 0;
      }
      let uid = firebase.database().ref().child("PodiumQuiz3App").push().key;

      let d = new Date();
      let n = d.toLocaleString();
      let data = {
        id: uid,
        Fullname: this.state.fullname,
        date: n,
        score: score,
        ville: ville,
      };

      let updates = {};
      let updates2 = {};

      updates["/PodiumQuiz3App/" + uid] = data;
      firebase
        .database()
        .ref()
        .update(updates)
        .then(
          this.setState({
            voted3: true,
          })
        );

      updates2["/PodiumQuiz3AppSave/" + uid] = data;
      firebase.database().ref().update(updates2);
      firebase
        .database()
        .ref("compteurvote3")
        .set(this.state.compteurvote3 + 1);

      setTimeout(() => {
        // podium
        let uid2 = firebase.database().ref().child("PodiumFinal").push().key;

        let data2 = {
          id: uid2,
          Fullname: this.state.fullname,
          date: n,
          score: this.state.note,
        };

        let updates3 = {};

        updates3["/PodiumFinal/" + uid2] = data2;
        firebase
          .database()
          .ref()
          .update(updates3)
          .then(
            this.setState({
              note: 0,
              voted: false,
            })
          );
      }, "1000");

      alert("your answer has been submitted");
    } else {
      alert("You have alredy voted");
    }
  }

  componentWillMount() {
    this.db = firebase.database();

    this.db.ref("page").on("value", (snap) => {
      this.setState({ page: snap.val() });
    });

    this.db.ref("compteurvote1").on("value", (snap) => {
      this.setState({ compteurvote1: snap.val() });
    });
    this.db.ref("compteurvote2").on("value", (snap) => {
      this.setState({ compteurvote2: snap.val() });
    });
    this.db.ref("compteurvote3").on("value", (snap) => {
      this.setState({ compteurvote3: snap.val() });
    });
  }
  componentDidMount() {
    if (localStorage.getItem("LocalUser") != "") {
      this.setState({ fullname: localStorage.getItem("LocalUser") });
    }
  }

  render() {
    var templatePageAttenteUtilisateur = (
      <div className="Rap">
        <div className="ContainerAttenreUtilisateur">
          <div>
            {" "}
            Patience ! <br />
            The questions will automatically appear when the time comes.{" "}
          </div>
        </div>
      </div>
    );

    var templateQui1 = (
      <div className="ContainerQuiz">
        <div className="titreQuizUsers"> What is the Capital of France? </div>
        <div className="corps">
          <div className="ResponseBase">
            <div
              className={
                this.state.R1 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
              onClick={this.handleReponse1}
            ></div>
            <div
              className={
                this.state.R1 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">1. Marseille</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.R2 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
              onClick={this.handleReponse2}
            ></div>
            <div
              className={
                this.state.R2 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">2. Lyon</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.R3 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
              onClick={this.handleReponse3}
            ></div>
            <div
              className={
                this.state.R3 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">3. Paris</div>
            </div>
          </div>
          <div className="btnValider">
            <button
              type="button"
              class="btn btn-success "
              onClick={this.handleSubmitQuiz1}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );

    var templateQui2 = (
      <div className="ContainerQuiz">
        <div className="titreQuizUsers">
          {" "}
          What is the Capital of Australia ?{" "}
        </div>

        <div className="corps">
          <div className="ResponseBase">
            <div
              className={
                this.state.Q2R1 === true
                  ? "ResponseCheckGreen"
                  : "ResponseCheck"
              }
              onClick={this.handleReponse1Q2}
            ></div>
            <div
              className={
                this.state.Q2R1 === true
                  ? "ResponseBlockGreen"
                  : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">1. Sydney</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.Q2R2 === true
                  ? "ResponseCheckGreen"
                  : "ResponseCheck"
              }
              onClick={this.handleReponse2Q2}
            ></div>
            <div
              className={
                this.state.Q2R2 === true
                  ? "ResponseBlockGreen"
                  : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">2. Melbourne</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.Q2R3 === true
                  ? "ResponseCheckGreen"
                  : "ResponseCheck"
              }
              onClick={this.handleReponse3Q2}
            ></div>
            <div
              className={
                this.state.Q2R3 === true
                  ? "ResponseBlockGreen"
                  : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">3. Brisbane</div>
            </div>
          </div>
          <div className="btnValider">
            <button
              type="button"
              class="btn btn-success "
              onClick={this.handleSubmitQuiz2}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );

    var templateQui3 = (
      <div className="ContainerQuiz">
        <div className="titreQuizUsers"> What is the Capital of Italy ? </div>

        <div className="corps">
          <div className="ResponseBase">
            <div
              className={
                this.state.Q3R1 === true
                  ? "ResponseCheckGreen"
                  : "ResponseCheck"
              }
              onClick={this.handleReponse1Q3}
            ></div>
            <div
              className={
                this.state.Q3R1 === true
                  ? "ResponseBlockGreen"
                  : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">1. Rome</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.Q3R2 === true
                  ? "ResponseCheckGreen"
                  : "ResponseCheck"
              }
              onClick={this.handleReponse2Q3}
            ></div>
            <div
              className={
                this.state.Q3R2 === true
                  ? "ResponseBlockGreen"
                  : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">2. Milan</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.Q3R3 === true
                  ? "ResponseCheckGreen"
                  : "ResponseCheck"
              }
              onClick={this.handleReponse3Q3}
            ></div>
            <div
              className={
                this.state.Q3R3 === true
                  ? "ResponseBlockGreen"
                  : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">3. Parma</div>
            </div>
          </div>
          <div className="btnValider">
            <button
              type="button"
              class="btn btn-success "
              onClick={this.handleSubmitQuiz3}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );

    var template = "";

    if (this.state.page === 0) {
      template = templatePageAttenteUtilisateur;
    } else if (this.state.page === 1 || this.state.page === 2) {
      template = templateQui1;
    } else if (this.state.page === 3 || this.state.page === 4) {
      template = templateQui2;
    } else if (
      this.state.page === 5 ||
      this.state.page === 6 ||
      this.state.page === 7
    ) {
      template = templateQui3;
    } else {
      template = templatePageAttenteUtilisateur;
    }

    return <div className="ContainerQuizUtilisateur">{template}</div>;
  }
}

export default QuizUtilisateur;
