import React, { Component } from "react";
import "./Global.css";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";

class Pays extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      sommaire: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillMount() {}
  componentDidMount() {}

  handleSubmit(event) {
    this.props.history.push("/Stats/formateur");
    event.preventDefault();
  }

  render() {
    return (
      <div className="ContainerStatistiques">
        <div className="titreStats">
          <div> Statistics by country</div>
        </div>

        <div className="container">
          <div className="containerBar">
            <div className="logoPays" onClick={this.handleSubmit}>
              Formateur 1
            </div>
            <div class="progressbar-wrapper">
              <div title="downloading" className="progressbar A1"></div>
            </div>
            <div className="PointPays"> 100 points</div>
          </div>

          <div className="containerBar">
            <div className="logoPays" onClick={this.handleSubmit}>
              Formateur 2
            </div>
            <div class="progressbar-wrapper">
              <div title="downloading" className="progressbar B1"></div>
            </div>
            <div className="PointPays"> 90 points</div>
          </div>

          <div className="containerBar">
            <div className="logoPays" onClick={this.handleSubmit}>
              Formateur 3
            </div>
            <div class="progressbar-wrapper">
              <div title="downloading" className="progressbar C1"></div>
            </div>
            <div className="PointPays"> 80 points</div>
          </div>

          <div className="containerBar">
            <div className="logoPays" onClick={this.handleSubmit}>
              Formateur 4
            </div>
            <div class="progressbar-wrapper">
              <div title="downloading" className="progressbar D1"></div>
            </div>
            <div className="PointPays"> 50 points</div>
          </div>

          <div className="containerBar">
            <div className="logoPays" onClick={this.handleSubmit}>
              Formateur 5
            </div>
            <div class="progressbar-wrapper">
              <div title="downloading" className="progressbar E1"></div>
            </div>
            <div className="PointPays"> 47 points</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pays;
