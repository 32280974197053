import React, { Component } from "react";
import "./SommaireUtilisateur.css";
import { withRouter } from "react-router-dom";

class SommaireUtilisateur extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: "",
      password: "",
      sended: false,
      SommaireUtilisateur: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }
  componentWillMount() {}
  componentDidMount() {}
  handleClick(event) {
    this.props.history.push("/quizUsers");
  }

  render() {
    return (
      <div className="ConatinerSommaireUtilisateur">
        <div className="titre"> Summary</div>
        <div className="VignetteMobile" onClick={this.handleClick}>
          <p>Quiz</p>
        </div>
        <div className="logoSommaireUtilisateur">
          {" "}
          <img
            src="https://zoom.henri8.com/IMG/Logo%20Sanofi.png"
            width={200}
          />
        </div>
      </div>
    );
  }
}

export default SommaireUtilisateur;
