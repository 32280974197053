import React, { Component } from "react";
import { HashRouter as Router, Route } from "react-router-dom";
import Admin from "../Components/Admin";
import Chat from "../Components/Chat";
import Moderation from "../Components/Moderation";
import Player from "../Components/Player";
import Login from "../Components/Formateur/Login";
import Sommaire from "../Components/Formateur/Sommaire";
import Quiz from "../Components/Formateur/Quiz";

import QuizFnim from "../Components/QuizFnim";
import "react-app-polyfill/ie11";

//utilisateur
import LoginUtilisateur from "../Components/Utilisateur/LoginUtilisateur";
import SommaireUtilisateur from "../Components/Utilisateur/SommaireUtilisateur";
import QuizUtilisateur from "../Components/Utilisateur/QuizUtilisateur";

//Statistiques
import Global from "../Components/Statistiques/Global";
import Pays from "../Components/Statistiques/Pays";
import Formateur from "../Components/Statistiques/Formateur";

class IndexRoute extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="IndexRoute">
        <Router>
          <div>
            <Route exact path="/" component={QuizFnim} />
            <Route exact path="/:token" component={QuizFnim} />

            {/*

              <Route exact path="/auth" component={Login} />
            <Route exact path="/register" component={LoginUtilisateur} />

            <Route exact path="/sommaire" component={Sommaire} />
            <Route
              exact
              path="/sommaireUsers"
              component={SommaireUtilisateur}
            />
            <Route exact path="/quiz" component={Quiz} />
            <Route exact path="/quizUsers" component={QuizUtilisateur} />

            <Route exact path="/Stats/global" component={Global} />
            <Route exact path="/Stats/pays" component={Pays} />
            <Route exact path="/Stats/formateur" component={Formateur} />

            

              <Route exact path="/:token" component={Moderation} />
  
     <Route exact path="/" component={Player} />
            <Route exact path="/:token" component={Player} />
            

  <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Home} />
         


 <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Home} />
  <Route exact path="/:token" component={Home} />
          
            <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Moderation} />
               <Route exact path="/:token" component={Admin} />

                <Route exact path="/:token" component={Moderation} />

       
            <Route exact path="/" component={Home} />
            <Route exact path="/:token" component={Home} />
           
            <Route exact path="/:token" component={Admin} />
             
            <Route exact path="/:token" component={Admin} />

             <Route exact path="/:token" component={Moderation} />

             
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/Chat" component={Chat} />
            <Route exact path="/Moderation" component={Moderation} />
            */}
          </div>
        </Router>
      </div>
    );
  }
}

export default IndexRoute;
