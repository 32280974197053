import React, { Component } from "react";
import "./Login.css";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";

class Login extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: "",
      password: "",
      sended: false,
      sommaire: false,
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {}
  componentDidMount() {}

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
    console.log(event.target.value);
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
    console.log(event.target.value);
  }
  handleSubmit(event) {
    let lastAtPos = this.state.email.lastIndexOf("@");
    let lastDotPos = this.state.email.lastIndexOf(".");

    if (
      !(
        lastAtPos < lastDotPos &&
        lastAtPos > 0 &&
        this.state.email.indexOf("@@") === -1 &&
        lastDotPos > 2 &&
        this.state.email.length - lastDotPos > 2
      )
    ) {
      alert("Please enter a valid email address");
    } else {
      this.setState({
        sended: true,
      });

      this.Inscription();
    }

    event.preventDefault();
  }

  handleValidation() {
    //let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Password
    if (this.state.password === "") {
      formIsValid = false;
      alert("The password field cannot be empty");
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  Inscription() {
    if (this.handleValidation()) {
      if (
        this.state.email == "quizz@henri8.com" &&
        this.state.password == "testHenri8"
      ) {
        this.props.history.push("/sommaire");

        this.setState({
          sommaire: true,
        });
      } else {
        alert("login ou mot de passe incorrect");
      }
    } else {
      this.setState({
        sended: false,
        sommaire: false,
      });
      console.log("not ok");
    }
  }

  render() {
    return (
      <div className="ContainerLogin">
        <form>
          <div className="form-group">
            <img
              src="https://zoom.henri8.com/IMG/Logo%20Sanofi.png"
              className="logoSanofi"
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="Login*"
              required
              value={this.state.email}
              onChange={this.handleChangeEmail}
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              className="form-control"
              name="password"
              placeholder="Password*"
              required
              value={this.state.password}
              onChange={this.handleChangePassword}
            />
          </div>
          <button
            type="button"
            class="btn btn-outline-success btn-block"
            onClick={this.handleSubmit}
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default Login;
