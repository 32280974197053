import React, { Component } from "react";
import screenfull from "screenfull";
import "./Player.css";
import "./Home.css";
//import Header from './Header';
//import Footer from './Footer';
import Chat from "./Chat";
import axios from "axios";

import { Firebase_Config } from "../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";
//import ReactPlayer from 'react-player';
import { io } from "socket.io-client";

var socket = io("", {
  transports: ["websocket"],
});

class Player extends Component {
  constructor(props, context) {
    super(props, context);

    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }
    this.state = {
      etat: 2,
      localstate: 2,
      url: null,
      pip: false,
      playing: true,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,

      iframeIVI: 0,
      iframeIVI2: 0,
      iframeIVI3: 0,
      iframeIVI4: 0,
      iframeIVI5: 0,
      iframeIVI6: 0,
      iframeIVI7: 0,
      iframeIVI8: 0,

      iframePlayer: 0,
      sessionid: "",
      urlYoutube: "",
      socketCloseGileadCART: 2,
      PosterGilead: 0,
    };

    this.handleFullscreen = this.handleFullscreen.bind(this);
    this.handleLocalState = this.handleLocalState.bind(this);
  }
  //
  componentWillMount() {}
  componentDidMount() {}

  componentWillUnmount() {}

  //

  componentDidUpdate() {}

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      localstate: 1,
      pip: false,
    });

    setTimeout(function () {
      screenfull.request();
    }, 200);
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handlePlay = () => {
    this.setState({ playing: true });
  };
  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state,
    });
  }

  handleFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.request();
    } else {
      //console.log('yes');
    }
  }

  play() {
    this.player.play();
  }
  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  renderLoadButton = (url, label) => {
    return (
      <button className="play-btn" onClick={() => this.load(url)}>
        {label}
      </button>
    );
  };

  ref = (player) => {
    this.player = player;
  };

  handleLocalState() {
    this.setState({ localstate: 1 });
    //console.log('yesy');
  }

  render() {
    const { url } = this.state;

    var templateHide = <div className="ContainerHide"></div>;

    //   const SEPARATOR = ' · ';
    var template2 = "";
    if (this.state.statusToken === 200) {
      var template1 = (
        <div className="PlayerFullScreen">
          {/*  
           <iframe
            src="//iframe.dacast.com/b/50026/c/468891"
            width="100%"
            height="100%"
            scrolling="no"
            allow="autoplay"
            title="fullPlayer"
          ></iframe>
          
          */}
          <iframe
            width="100%"
            height="100%"
            src={this.state.urlYoutube}
            //src="//iframe.dacast.com/b/50026/c/428043"
            //src="//iframe.dacast.com/live/d6abfb2d213d0c9a2d231908d9336438/1c9e6469ffb02b9d87983b45dad84251"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation"
          ></iframe>
          {template2}
          <Chat />
          {templateHide}
        </div>
      );
    }

    if (this.state.statusToken === "err") {
      template1 = (
        <div className="containerIncorrect">
          <div className="AccesRefuse">Accès refusé</div>
          <hr className="hr"></hr>
          <div className="textIncorrect">
            <div className="first">
              Le lien sur lequel vous avez cliqué semble invalide. Veuillez
              réessayer.
            </div>
            Si vous êtes le médecin inscrit destinataire de ce lien alors vous
            pouvez appeler notre hotline au
            <span style={{ color: "#0000FF" }}> 01 44 90 00 59</span>
          </div>
        </div>
      );
    }

    {
      /**
       *  template1 = (
      <div className="containerIncorrect">
        <div className="AccesRefuse">Accès refusé</div>
        <hr className="hr"></hr>
        <div className="textIncorrect">
          <div className="first">
            Le lien sur lequel vous avez cliqué semble invalide. Veuillez
            réessayer.
          </div>
          Si vous êtes le médecin inscrit destinataire de ce lien alors vous
          pouvez appeler notre hotline au
          <span style={{ color: '#0000FF' }}> 01 44 90 00 59</span>
        </div>
      </div>
    );
       */
    }

    return <div>{template1}</div>;
  }
}

export default Player;
