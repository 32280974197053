import React, { Component } from "react";
import "./QuizFnim.css";
import Q1 from "./Quiz/Q1";
import Q2 from "./Quiz/Q2";
import Q3 from "./Quiz/Q3";
import Q4 from "./Quiz/Q4";
import Q5 from "./Quiz/Q5";
import Merci from "./Quiz/Merci";
import Q2bis from "./Quiz/Q2bis";

class QuizFnim extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      etat: 0,
      data: "",
    };
  }
  componentWillMount() {}
  componentDidMount() {}

  handleCallback = (childData) => {
    // Update the name in the component's state
    this.setState({ etat: childData });
  };

  render() {
    var template = (
      <Q1
        parentCallback={this.handleCallback}
        token={this.props.match.params.token}
      />
    );

    if (this.state.etat == 1) {
      template = (
        <Q2
          parentCallback={this.handleCallback}
          token={this.props.match.params.token}
        />
      );
    } else if (this.state.etat == 2.1) {
      template = (
        <Q2bis
          parentCallback={this.handleCallback}
          token={this.props.match.params.token}
        />
      );
    } else if (this.state.etat == 2) {
      template = (
        <Q3
          parentCallback={this.handleCallback}
          token={this.props.match.params.token}
        />
      );
    } else if (this.state.etat == 3) {
      template = (
        <Q4
          parentCallback={this.handleCallback}
          token={this.props.match.params.token}
        />
      );
    } else if (this.state.etat == 4) {
      template = (
        <Q5
          parentCallback={this.handleCallback}
          token={this.props.match.params.token}
        />
      );
    } else if (this.state.etat == 5) {
      template = <Merci />;
    }
    console.log(" Etat :" + this.state.etat);
    return (
      <div className="ContainerQuizFnim">
        <div className="HeaderFnim">
          {" "}
          <div className="logoFnim">
            {" "}
            <img
              src="https://www.lafnim.com/images/logo/logo.png"
              alt="logoFnim"
            />{" "}
          </div>
        </div>
        <div className="subTitle">
          Les Relations Prestataires - Industrie Pharma
        </div>

        <div className="containerQuizInterieur">{template}</div>
      </div>
    );
  }
}

export default QuizFnim;
