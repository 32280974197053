import React, { Component } from "react";
import "./Q2bis.css";
import axios from "axios";

class Q2bis extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      Q2bis: "",
      etat: 2,

      ra: "",
      rb: "",
      rc: "",
      rd: "",
      re: "",

      a0: false,
      a1: false,
      a2: false,
      a3: false,
      a4: false,
      a4: false,
      a5: false,
      a6: false,
      a7: false,
      a8: false,
      a9: false,
      a10: false,

      b1: false,
      b2: false,
      b3: false,
      b4: false,

      c1: false,
      c2: false,

      d1: false,
      d2: false,
      d3: false,

      e1: false,
      e2: false,
      e3: false,

      GOOD: false,
      BAD: false,

      email_invite: "",
    };
  }
  componentWillMount() {}
  componentDidMount() {
    this.getUserbyToken();
  }
  getUserbyToken = () => {
    setTimeout(() => {
      axios
        .post(
          `https://quizfnim.henri8.com/api/users/getUserByToken`,
          {
            token: this.props.token,
          } /*,
          {
            headers: {
              "Access-Control-Request-Headers":
                "X-Requested-With,X-Auth-Token,Content-Type,  Content-Length, Authorization",
            },
          }*/
        )
        .then((res) => {
          this.setState({
            email_invite: res.data.email_invite,
          });
        })
        .catch((err) => console.log(err));
    }, 200);
  };

  //RF
  handleChangeRA0 = () => {
    this.setState({ ra: "0" });
    this.setState({ a0: true });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA1 = () => {
    this.setState({ ra: "1" });
    this.setState({ a0: false });
    this.setState({ a1: true });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA2 = () => {
    this.setState({ ra: "2" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: true });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA3 = () => {
    this.setState({ ra: "3" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: true });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA4 = () => {
    this.setState({ ra: "4" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: true });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA5 = () => {
    this.setState({ ra: "5" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: true });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA6 = () => {
    this.setState({ ra: "6" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: true });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA7 = () => {
    this.setState({ ra: "7" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: true });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA8 = () => {
    this.setState({ ra: "8" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: true });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA9 = () => {
    this.setState({ ra: "9" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: true });
    this.setState({ a10: false });
  };

  handleChangeRA10 = () => {
    this.setState({ ra: "10" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: true });
  };

  // RB

  handleChangeRB1 = () => {
    this.setState({ rb: "Jamais" });
    this.setState({ b1: true });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: false });
  };

  handleChangeRB2 = () => {
    this.setState({ rb: "Rarement" });
    this.setState({ b1: false });
    this.setState({ b2: true });
    this.setState({ b3: false });
    this.setState({ b4: false });
  };

  handleChangeRB3 = () => {
    this.setState({ rb: "Fréquemment" });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: true });
    this.setState({ b4: false });
  };

  handleChangeRB4 = () => {
    this.setState({ rb: "Tout le temps" });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: false });
    this.setState({ b4: true });
  };

  // RC

  handleChangeRC1 = () => {
    this.setState({ rc: "Dans la majorité des cas" });
    this.setState({ c1: true });
    this.setState({ c2: false });
  };

  handleChangeRC2 = () => {
    this.setState({ rc: "De moins en moins" });
    this.setState({ c1: false });
    this.setState({ c2: true });
  };

  //RD

  handleChangeRD1 = () => {
    this.setState({ rd: "Toujours" });
    this.setState({ d1: true });
    this.setState({ d2: false });
    this.setState({ d3: false });
  };

  handleChangeRD2 = () => {
    this.setState({ rd: "Dans la majorité des cas" });
    this.setState({ d1: false });
    this.setState({ d2: true });
    this.setState({ d3: false });
  };

  handleChangeRD3 = () => {
    this.setState({ rd: "De moins en moins" });
    this.setState({ d1: false });
    this.setState({ d2: false });
    this.setState({ d3: true });
  };

  //RE

  handleChangeRE1 = () => {
    this.setState({ re: "Jamais" });
    this.setState({ e1: true });
    this.setState({ e2: false });
    this.setState({ e3: false });
  };

  handleChangeRE2 = () => {
    this.setState({ re: "parfois" });
    this.setState({ e1: false });
    this.setState({ e2: true });
    this.setState({ e3: false });
  };

  handleChangeRE3 = () => {
    this.setState({ re: "toujours" });
    this.setState({ e1: false });
    this.setState({ e2: false });
    this.setState({ e3: true });
  };

  handleSubmitQ2bis = () => {
    if (
      (this.state.ra &&
        this.state.rb &&
        this.state.rc &&
        this.state.rd &&
        this.state.re) != ""
    ) {
      this.setState({ GOOD: true });

      // début fonction

      axios
        .put(`https://quizfnim.henri8.com/api/users/Quiz2_2`, {
          email_invite: this.state.email_invite,
          Q2_2RA: this.state.ra,
          Q2_2RB: this.state.rb,
          Q2_2RC: this.state.rc,
          Q2_2RD: this.state.rd,
          Q2_2RE: this.state.re,
        })
        .then((res) => {
          setTimeout(() => {
            this.setState({ GOOD: false });
            this.props.parentCallback(this.state.etat);
          }, "3000");
        })
        .catch((err) => alert("Une erreur s'est produite"));
    } else {
      this.setState({ BAD: true });

      setTimeout(() => {
        this.setState({ BAD: false });
      }, "5000");
    }
  };

  render() {
    var alertGOO = "";
    var alertBAD = "";

    if (this.state.GOOD == true) {
      alertGOO = (
        <div className="ContainerAlerte">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Vos réponses ont bien été prise en compte
          </div>
        </div>
      );
    }

    if (this.state.BAD == true) {
      alertBAD = (
        <div className="ContainerAlerteBad">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Veuillez répondre à toutes les questions avant de valider
          </div>
        </div>
      );
    }

    return (
      <div className="ContainerQ">
        <div className="TitreQuestion">2.2 Présentation des recos </div>
        <div className="sousTitre">
          {" "}
          a. Est-ce que le temps accordé pour la présentation est pour vous
          aujourd’hui suffisant ? 0 (totalement insuffisant) - 10 (totalement
          satisfaisant)
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRA0}
            className={
              this.state.a0 ? "vert button  Right" : "button3 button  Right"
            }
          >
            0{" "}
          </div>
          <div
            onClick={this.handleChangeRA1}
            className={this.state.a1 ? "vert button  " : "button3 button  "}
          >
            {" "}
            1{" "}
          </div>
          <div
            onClick={this.handleChangeRA2}
            className={this.state.a2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            2{" "}
          </div>
          <div
            onClick={this.handleChangeRA3}
            className={this.state.a3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            3{" "}
          </div>
          <div
            onClick={this.handleChangeRA4}
            className={this.state.a4 ? "vert button  " : "button3 button  "}
          >
            {" "}
            4{" "}
          </div>
          <div
            onClick={this.handleChangeRA5}
            className={this.state.a5 ? "vert button  " : "button3 button  "}
          >
            {" "}
            5{" "}
          </div>
          <div
            onClick={this.handleChangeRA6}
            className={this.state.a6 ? "vert button  " : "button3 button  "}
          >
            {" "}
            6{" "}
          </div>
          <div
            onClick={this.handleChangeRA7}
            className={this.state.a7 ? "vert button  " : "button3 button  "}
          >
            {" "}
            7{" "}
          </div>
          <div
            onClick={this.handleChangeRA8}
            className={this.state.a8 ? "vert button  " : "button3 button  "}
          >
            {" "}
            8{" "}
          </div>
          <div
            onClick={this.handleChangeRA9}
            className={this.state.a9 ? "vert button  " : "button3 button  "}
          >
            {" "}
            9{" "}
          </div>
          <div
            onClick={this.handleChangeRA10}
            className={this.state.a10 ? "vert button  " : "button3 button  "}
          >
            {" "}
            10{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          b. Est-ce que les décideurs sont présents aux présentations des
          recommandations ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRB1}
            className={
              this.state.b1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            Jamais
          </div>
          <div
            onClick={this.handleChangeRB2}
            className={this.state.b2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Rarement
          </div>
          <div
            onClick={this.handleChangeRB3}
            className={this.state.b3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Fréquemment
          </div>
          <div
            onClick={this.handleChangeRB4}
            className={this.state.b4 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Tout le temps
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          c. Avez-vous une réponse systématiquement aux recommandations
          présentées ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRC1}
            className={
              this.state.c1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            Dans la majorité des cas
          </div>

          <div
            onClick={this.handleChangeRC2}
            className={this.state.c2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            De moins en moins
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          d. Obtenez-vous des debriefs sur les recommandations présentées ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRD1}
            className={
              this.state.d1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            Toujours
          </div>
          <div
            onClick={this.handleChangeRD2}
            className={this.state.d2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Dans la majorité des cas
          </div>
          <div
            onClick={this.handleChangeRD3}
            className={this.state.d3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            De moins en moins{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          e. En cas de projet gagné et abandonné avez-vous reçu un dédommagement
          ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRE1}
            className={this.state.e1 ? "vert button  " : "button3 button  "}
          >
            Jamais
          </div>
          <div
            onClick={this.handleChangeRE2}
            className={this.state.e2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            parfois
          </div>

          <div
            onClick={this.handleChangeRE3}
            className={this.state.e3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            toujours
          </div>
        </div>

        <div className="containerBtnValider">
          <div className="button button10" onClick={this.handleSubmitQ2bis}>
            {" "}
            Valider
          </div>
        </div>
        {alertGOO}
        {alertBAD}
      </div>
    );
  }
}

export default Q2bis;
