import React, { Component } from "react";
import "./Q2.css";
import axios from "axios";

class Q2 extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      Q2: "",
      etat: 2.1,

      ra: "",
      rb: "",
      rc: "",
      rd: "",
      re: "",
      rf: "",
      rg: "",
      rh: "",
      ri: "",
      rj: "",

      a1: false,
      a2: false,
      a3: false,
      a4: false,

      b1: false,
      b2: false,
      b3: false,

      c1: false,
      c2: false,
      c3: false,

      d1: false,
      d2: false,
      d3: false,

      e1: false,
      e2: false,
      e3: false,
      e4: false,

      f0: false,
      f1: false,
      f2: false,
      f3: false,
      f4: false,
      f5: false,
      f6: false,
      f7: false,
      f8: false,
      f9: false,
      f10: false,

      g0: false,
      g1: false,
      g2: false,
      g3: false,
      g4: false,
      g5: false,
      g6: false,
      g7: false,
      g8: false,
      g9: false,
      g10: false,

      h1: false,
      h2: false,
      h3: false,
      h4: false,

      i1: false,
      i2: false,
      i3: false,
      i4: false,

      j1: false,
      j2: false,
      j3: false,
      i4: false,

      GOOD: false,
      BAD: false,

      email_invite: "",
    };
  }
  componentWillMount() {}
  componentDidMount() {
    this.getUserbyToken();
  }
  getUserbyToken = () => {
    setTimeout(() => {
      axios
        .post(
          `https://quizfnim.henri8.com/api/users/getUserByToken`,
          {
            token: this.props.token,
          } /*,
          {
            headers: {
              "Access-Control-Request-Headers":
                "X-Requested-With,X-Auth-Token,Content-Type,  Content-Length, Authorization",
            },
          }*/
        )
        .then((res) => {
          this.setState({
            email_invite: res.data.email_invite,
          });
        })
        .catch((err) => console.log(err));
    }, 200);
  };

  //RA

  handleChangeRA1 = () => {
    this.setState({ ra: "Mauvaise" });
    this.setState({ a1: true });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
  };

  handleChangeRA2 = () => {
    this.setState({ ra: "Moyenne" });
    this.setState({ a1: false });
    this.setState({ a2: true });
    this.setState({ a3: false });
    this.setState({ a4: false });
  };

  handleChangeRA3 = () => {
    this.setState({ ra: "Correcte" });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: true });
    this.setState({ a4: false });
  };

  handleChangeRA4 = () => {
    this.setState({ ra: "Excellente" });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: true });
  };

  // RB

  handleChangeRB1 = () => {
    this.setState({ rb: "Jamais fait" });
    this.setState({ b1: true });
    this.setState({ b2: false });
    this.setState({ b3: false });
  };

  handleChangeRB2 = () => {
    this.setState({ rb: "Ponctuellement" });
    this.setState({ b1: false });
    this.setState({ b2: true });
    this.setState({ b3: false });
  };

  handleChangeRB3 = () => {
    this.setState({ rb: "Fréquemment" });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: true });
  };

  // RC

  handleChangeRC1 = () => {
    this.setState({ rc: "Alternants/Juniors" });
    this.setState({ c1: true });
    this.setState({ c2: false });
    this.setState({ c3: false });
  };

  handleChangeRC2 = () => {
    this.setState({ rc: "Chef de produit confirmé" });
    this.setState({ c1: false });
    this.setState({ c2: true });
    this.setState({ c3: false });
  };

  handleChangeRC3 = () => {
    this.setState({ rc: "Managers" });
    this.setState({ c1: false });
    this.setState({ c2: false });
    this.setState({ c3: true });
  };

  //RD

  handleChangeRD1 = () => {
    this.setState({ rd: "Le plus souvent je l’ignore" });
    this.setState({ d1: true });
    this.setState({ d2: false });
    this.setState({ d3: false });
  };

  handleChangeRD2 = () => {
    this.setState({ rd: "Moins de 3, 3 ou 4" });
    this.setState({ d1: false });
    this.setState({ d2: true });
    this.setState({ d3: false });
  };

  handleChangeRD3 = () => {
    this.setState({ rd: "Plus de 4" });
    this.setState({ d1: false });
    this.setState({ d2: false });
    this.setState({ d3: true });
  };

  //RE

  handleChangeRE1 = () => {
    this.setState({ re: "De plus en plus fréquemment" });
    this.setState({ e1: true });
    this.setState({ e2: false });
    this.setState({ e3: false });
    this.setState({ e4: false });
  };

  handleChangeRE2 = () => {
    this.setState({ re: "Assez souvent" });
    this.setState({ e1: false });
    this.setState({ e2: true });
    this.setState({ e3: false });
    this.setState({ e4: false });
  };

  handleChangeRE3 = () => {
    this.setState({ re: "Pas plus souvent qu’auparavant" });
    this.setState({ e1: false });
    this.setState({ e2: false });
    this.setState({ e3: true });
    this.setState({ e4: false });
  };

  handleChangeRE4 = () => {
    this.setState({ re: "Absolument pas" });
    this.setState({ e1: false });
    this.setState({ e2: false });
    this.setState({ e3: false });
    this.setState({ e4: true });
  };

  //RF
  handleChangeRF0 = () => {
    this.setState({ rf: "0 semaine" });
    this.setState({ f0: true });
    this.setState({ f1: false });
    this.setState({ f2: false });
    this.setState({ f3: false });
    this.setState({ f4: false });
    this.setState({ f5: false });
    this.setState({ f6: false });
    this.setState({ f7: false });
    this.setState({ f8: false });
    this.setState({ f9: false });
    this.setState({ f10: false });
  };

  handleChangeRF1 = () => {
    this.setState({ rf: "1 semaine" });
    this.setState({ f0: false });
    this.setState({ f1: true });
    this.setState({ f2: false });
    this.setState({ f3: false });
    this.setState({ f4: false });
    this.setState({ f5: false });
    this.setState({ f6: false });
    this.setState({ f7: false });
    this.setState({ f8: false });
    this.setState({ f9: false });
    this.setState({ f10: false });
  };

  handleChangeRF2 = () => {
    this.setState({ rf: "2 semaines" });
    this.setState({ f0: false });
    this.setState({ f1: false });
    this.setState({ f2: true });
    this.setState({ f3: false });
    this.setState({ f4: false });
    this.setState({ f5: false });
    this.setState({ f6: false });
    this.setState({ f7: false });
    this.setState({ f8: false });
    this.setState({ f9: false });
    this.setState({ f10: false });
  };

  handleChangeRF3 = () => {
    this.setState({ rf: "3 semaines" });
    this.setState({ f0: false });
    this.setState({ f1: false });
    this.setState({ f2: false });
    this.setState({ f3: true });
    this.setState({ f4: false });
    this.setState({ f5: false });
    this.setState({ f6: false });
    this.setState({ f7: false });
    this.setState({ f8: false });
    this.setState({ f9: false });
    this.setState({ f10: false });
  };

  handleChangeRF4 = () => {
    this.setState({ rf: "4 semaines" });
    this.setState({ f0: false });
    this.setState({ f1: false });
    this.setState({ f2: false });
    this.setState({ f3: false });
    this.setState({ f4: true });
    this.setState({ f5: false });
    this.setState({ f6: false });
    this.setState({ f7: false });
    this.setState({ f8: false });
    this.setState({ f9: false });
    this.setState({ f10: false });
  };

  handleChangeRF5 = () => {
    this.setState({ rf: "5 semaines" });
    this.setState({ f0: false });
    this.setState({ f1: false });
    this.setState({ f2: false });
    this.setState({ f3: false });
    this.setState({ f4: false });
    this.setState({ f5: true });
    this.setState({ f6: false });
    this.setState({ f7: false });
    this.setState({ f8: false });
    this.setState({ f9: false });
    this.setState({ f10: false });
  };

  handleChangeRF6 = () => {
    this.setState({ rf: "6 semaines" });
    this.setState({ f0: false });
    this.setState({ f1: false });
    this.setState({ f2: false });
    this.setState({ f3: false });
    this.setState({ f4: false });
    this.setState({ f5: false });
    this.setState({ f6: true });
    this.setState({ f7: false });
    this.setState({ f8: false });
    this.setState({ f9: false });
    this.setState({ f10: false });
  };

  handleChangeRF7 = () => {
    this.setState({ rf: "7 semaines" });
    this.setState({ f0: false });
    this.setState({ f1: false });
    this.setState({ f2: false });
    this.setState({ f3: false });
    this.setState({ f4: false });
    this.setState({ f5: false });
    this.setState({ f6: false });
    this.setState({ f7: true });
    this.setState({ f8: false });
    this.setState({ f9: false });
    this.setState({ f10: false });
  };

  handleChangeRF8 = () => {
    this.setState({ rf: "8 semaines" });
    this.setState({ f0: false });
    this.setState({ f1: false });
    this.setState({ f2: false });
    this.setState({ f3: false });
    this.setState({ f4: false });
    this.setState({ f5: false });
    this.setState({ f6: false });
    this.setState({ f7: false });
    this.setState({ f8: true });
    this.setState({ f9: false });
    this.setState({ f10: false });
  };

  handleChangeRF9 = () => {
    this.setState({ rf: "9 semaines" });
    this.setState({ f0: false });
    this.setState({ f1: false });
    this.setState({ f2: false });
    this.setState({ f3: false });
    this.setState({ f4: false });
    this.setState({ f5: false });
    this.setState({ f6: false });
    this.setState({ f7: false });
    this.setState({ f8: false });
    this.setState({ f9: true });
    this.setState({ f10: false });
    this.setState({ f11: false });
  };

  handleChangeRF10 = () => {
    this.setState({ rf: "10 semaines" });
    this.setState({ f0: false });
    this.setState({ f1: false });
    this.setState({ f2: false });
    this.setState({ f3: false });
    this.setState({ f4: false });
    this.setState({ f5: false });
    this.setState({ f6: false });
    this.setState({ f7: false });
    this.setState({ f8: false });
    this.setState({ f9: false });
    this.setState({ f10: true });
  };

  //RG

  handleChangeRG0 = () => {
    this.setState({ rg: "0" });
    this.setState({ g0: true });
    this.setState({ g1: false });
    this.setState({ g2: false });
    this.setState({ g3: false });
    this.setState({ g4: false });
    this.setState({ g5: false });
    this.setState({ g6: false });
    this.setState({ g7: false });
    this.setState({ g8: false });
    this.setState({ g9: false });
    this.setState({ g10: false });
  };

  handleChangeRG1 = () => {
    this.setState({ rg: "1" });
    this.setState({ g0: false });
    this.setState({ g1: true });
    this.setState({ g2: false });
    this.setState({ g3: false });
    this.setState({ g4: false });
    this.setState({ g5: false });
    this.setState({ g6: false });
    this.setState({ g7: false });
    this.setState({ g8: false });
    this.setState({ g9: false });
    this.setState({ g10: false });
  };

  handleChangeRG2 = () => {
    this.setState({ rg: "2" });
    this.setState({ g0: false });
    this.setState({ g1: false });
    this.setState({ g2: true });
    this.setState({ g3: false });
    this.setState({ g4: false });
    this.setState({ g5: false });
    this.setState({ g6: false });
    this.setState({ g7: false });
    this.setState({ g8: false });
    this.setState({ g9: false });
    this.setState({ g10: false });
  };

  handleChangeRG3 = () => {
    this.setState({ rg: "3" });
    this.setState({ g0: false });
    this.setState({ g1: false });
    this.setState({ g2: false });
    this.setState({ g3: true });
    this.setState({ g4: false });
    this.setState({ g5: false });
    this.setState({ g6: false });
    this.setState({ g7: false });
    this.setState({ g8: false });
    this.setState({ g9: false });
    this.setState({ g10: false });
  };

  handleChangeRG4 = () => {
    this.setState({ rg: "4" });
    this.setState({ g0: false });
    this.setState({ g1: false });
    this.setState({ g2: false });
    this.setState({ g3: false });
    this.setState({ g4: true });
    this.setState({ g5: false });
    this.setState({ g6: false });
    this.setState({ g7: false });
    this.setState({ g8: false });
    this.setState({ g9: false });
    this.setState({ g10: false });
  };

  handleChangeRG5 = () => {
    this.setState({ rg: "5" });
    this.setState({ g0: false });
    this.setState({ g1: false });
    this.setState({ g2: false });
    this.setState({ g3: false });
    this.setState({ g4: false });
    this.setState({ g5: true });
    this.setState({ g6: false });
    this.setState({ g7: false });
    this.setState({ g8: false });
    this.setState({ g9: false });
    this.setState({ g10: false });
  };

  handleChangeRG6 = () => {
    this.setState({ rg: "6" });
    this.setState({ g0: false });
    this.setState({ g1: false });
    this.setState({ g2: false });
    this.setState({ g3: false });
    this.setState({ g4: false });
    this.setState({ g5: false });
    this.setState({ g6: true });
    this.setState({ g7: false });
    this.setState({ g8: false });
    this.setState({ g9: false });
    this.setState({ g10: false });
  };

  handleChangeRG7 = () => {
    this.setState({ rg: "7" });
    this.setState({ g0: false });
    this.setState({ g1: false });
    this.setState({ g2: false });
    this.setState({ g3: false });
    this.setState({ g4: false });
    this.setState({ g5: false });
    this.setState({ g6: false });
    this.setState({ g7: true });
    this.setState({ g8: false });
    this.setState({ g9: false });
    this.setState({ g10: false });
  };

  handleChangeRG8 = () => {
    this.setState({ rg: "8" });
    this.setState({ g0: false });
    this.setState({ g1: false });
    this.setState({ g2: false });
    this.setState({ g3: false });
    this.setState({ g4: false });
    this.setState({ g5: false });
    this.setState({ g6: false });
    this.setState({ g7: false });
    this.setState({ g8: true });
    this.setState({ g9: false });
    this.setState({ g10: false });
  };

  handleChangeRG9 = () => {
    this.setState({ rg: "9" });
    this.setState({ g0: false });
    this.setState({ g1: false });
    this.setState({ g2: false });
    this.setState({ g3: false });
    this.setState({ g4: false });
    this.setState({ g5: false });
    this.setState({ g6: false });
    this.setState({ g7: false });
    this.setState({ g8: false });
    this.setState({ g9: true });
    this.setState({ g10: false });
  };

  handleChangeRG10 = () => {
    this.setState({ rg: "10" });
    this.setState({ g0: false });
    this.setState({ g1: false });
    this.setState({ g2: false });
    this.setState({ g3: false });
    this.setState({ g4: false });
    this.setState({ g5: false });
    this.setState({ g6: false });
    this.setState({ g7: false });
    this.setState({ g8: false });
    this.setState({ g9: false });
    this.setState({ g10: true });
  };

  //RH

  handleChangeRH1 = () => {
    this.setState({ rh: "Rarement" });
    this.setState({ h1: true });
    this.setState({ h2: false });
    this.setState({ h3: false });
    this.setState({ h4: false });
  };

  handleChangeRH2 = () => {
    this.setState({ rh: "De temps en temps" });
    this.setState({ h1: false });
    this.setState({ h2: true });
    this.setState({ h3: false });
    this.setState({ h4: false });
  };

  handleChangeRH3 = () => {
    this.setState({ rh: "Souvent" });
    this.setState({ h1: false });
    this.setState({ h2: false });
    this.setState({ h3: true });
    this.setState({ h4: false });
  };

  handleChangeRH4 = () => {
    this.setState({ rh: "Toujours" });
    this.setState({ h1: false });
    this.setState({ h2: false });
    this.setState({ h3: false });
    this.setState({ h4: true });
  };

  //RI
  handleChangeRI1 = () => {
    this.setState({ ri: "Rarement" });
    this.setState({ i1: true });
    this.setState({ i2: false });
    this.setState({ i3: false });
    this.setState({ i4: false });
  };

  handleChangeRI2 = () => {
    this.setState({ ri: "De temps en temps" });
    this.setState({ i1: false });
    this.setState({ i2: true });
    this.setState({ i3: false });
    this.setState({ i4: false });
  };

  handleChangeRI3 = () => {
    this.setState({ ri: "Souvent" });
    this.setState({ i1: false });
    this.setState({ i2: false });
    this.setState({ i3: true });
    this.setState({ i4: false });
  };

  handleChangeRI4 = () => {
    this.setState({ ri: "Toujours" });
    this.setState({ i1: false });
    this.setState({ i2: false });
    this.setState({ i3: false });
    this.setState({ i4: true });
  };

  //RJ
  handleChangeRJ1 = () => {
    this.setState({ rj: "Rarement" });
    this.setState({ j1: true });
    this.setState({ j2: false });
    this.setState({ j3: false });
    this.setState({ j4: false });
  };

  handleChangeRJ2 = () => {
    this.setState({ rj: "De temps en temps" });
    this.setState({ j1: false });
    this.setState({ j2: true });
    this.setState({ j3: false });
    this.setState({ j4: false });
  };

  handleChangeRJ3 = () => {
    this.setState({ rj: "Souvent" });
    this.setState({ j1: false });
    this.setState({ j2: false });
    this.setState({ j3: true });
    this.setState({ j4: false });
  };

  handleChangeRJ4 = () => {
    this.setState({ rj: "Toujours" });
    this.setState({ j1: false });
    this.setState({ j2: false });
    this.setState({ j3: false });
    this.setState({ j4: true });
  };

  handleSubmitQ2 = () => {
    console.log(this.state.ra + this.state.rb + this.state.rc + this.state.rd);
    if (
      (this.state.ra &&
        this.state.rb &&
        this.state.rc &&
        this.state.rd &&
        this.state.re &&
        this.state.rf &&
        this.state.rg &&
        this.state.rh &&
        this.state.ri &&
        this.state.rj) != ""
    ) {
      this.setState({ GOOD: true });

      //fonction

      axios
        .put(`https://quizfnim.henri8.com/api/users/Quiz2`, {
          email_invite: this.state.email_invite,
          Q2RA: this.state.ra,
          Q2RB: this.state.rb,
          Q2RC: this.state.rc,
          Q2RD: this.state.rd,
          Q2RE: this.state.re,
          Q2RF: this.state.rf,
          Q2RG: this.state.rg,
          Q2RH: this.state.rh,
          Q2RI: this.state.ri,
          Q2RJ: this.state.rj,
        })
        .then((res) => {
          setTimeout(() => {
            this.setState({ GOOD: false });
            this.props.parentCallback(this.state.etat);
          }, "3000");
        })
        .catch((err) => alert("Une erreur s'est produite"));
    } else {
      this.setState({ BAD: true });

      setTimeout(() => {
        this.setState({ BAD: false });
      }, "5000");
    }
  };

  render() {
    var alertGOO = "";
    var alertBAD = "";

    if (this.state.GOOD == true) {
      alertGOO = (
        <div className="ContainerAlerte">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Vos réponses ont bien été prise en compte
          </div>
        </div>
      );
    }

    if (this.state.BAD == true) {
      alertBAD = (
        <div className="ContainerAlerteBad">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Veuillez répondre à toutes les questions avant de valider
          </div>
        </div>
      );
    }

    return (
      <div className="ContainerQ">
        <div className="TitreQuestion">2.1. Brief </div>
        <div className="sousTitre">
          {" "}
          a. Comment jugez-vous, en moyenne, la qualité des briefs que vous
          recevez (clarté du contexte et des enjeux, précision des attentes du
          laboratoire) ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRA1}
            className={
              this.state.a1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            Mauvaise
          </div>
          <div
            className="button button6"
            onClick={this.handleChangeRA2}
            className={this.state.a2 ? "vert button  " : "button2 button"}
          >
            {" "}
            Moyenne
          </div>

          <div
            className="button button8"
            onClick={this.handleChangeRA3}
            className={this.state.a3 ? "vert button  " : "button2 button"}
          >
            {" "}
            Correcte
          </div>

          <div
            className="button button10"
            onClick={this.handleChangeRA4}
            className={this.state.a4 ? "vert button  " : "button2 button"}
          >
            {" "}
            Excellente
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          b. Vous arrive-t-il de participer à des blind briefs (plusieurs
          agences en même temps) :
        </div>

        <div className="itemQ">
          <div
            className="button button4 Right"
            onClick={this.handleChangeRB1}
            className={
              this.state.b1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            Jamais fait
          </div>
          <div
            className="button button6"
            onClick={this.handleChangeRB2}
            className={this.state.b2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Ponctuellement
          </div>

          <div
            className="button button8"
            onClick={this.handleChangeRB3}
            className={this.state.b3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Fréquemment
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          c. Quel est la séniorité des personnes vous faisant les briefs, le
          plus souvent ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRC1}
            className={
              this.state.c1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            Alternants/Juniors
          </div>
          <div
            onClick={this.handleChangeRC2}
            className={this.state.c2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Chef de produit confirmé
          </div>

          <div
            onClick={this.handleChangeRC3}
            className={this.state.c3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Managers
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          d. Dans les appels d’offre auxquels vous participez, quel est le
          nombre de prestataires sollicités (hors congrès) ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRD1}
            className={
              this.state.d1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            Le plus souvent je l’ignore
          </div>
          <div
            onClick={this.handleChangeRD2}
            className={this.state.d2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Moins de 3, 3 ou 4
          </div>

          <div
            onClick={this.handleChangeRD3}
            className={this.state.d3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Plus de 4{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          e. Constatez-vous une réduction du délai de réponse aux
          briefs/interrogations ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRE1}
            className={
              this.state.e1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            De plus en plus fréquemment
          </div>
          <div
            onClick={this.handleChangeRE2}
            className={this.state.e2 ? "vert button " : "button3 button "}
          >
            {" "}
            Assez souvent
          </div>

          <div
            onClick={this.handleChangeRE3}
            className={this.state.e3 ? "vert button " : "button3 button "}
          >
            {" "}
            Pas plus souvent qu’auparavant{" "}
          </div>
          <div
            onClick={this.handleChangeRE4}
            className={this.state.e4 ? "vert button " : "button3 button "}
          >
            {" "}
            Absolument pas{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          f. Quel est pour vous un délai de réponse acceptable à un brief
          (plusieurs réponses possibles) ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRF0}
            className={
              this.state.f0 ? "vert button Right" : "button3 button Right"
            }
          >
            0 semaine
          </div>
          <div
            onClick={this.handleChangeRF1}
            className={this.state.f1 ? "vert button " : "button3 button "}
          >
            {" "}
            1 semaine{" "}
          </div>
          <div
            onClick={this.handleChangeRF2}
            className={this.state.f2 ? "vert button " : "button3 button "}
          >
            {" "}
            2 semaines{" "}
          </div>
          <div
            onClick={this.handleChangeRF3}
            className={this.state.f3 ? "vert button " : "button3 button "}
          >
            {" "}
            3 semaines{" "}
          </div>
          <div
            onClick={this.handleChangeRF4}
            className={this.state.f4 ? "vert button " : "button3 button "}
          >
            {" "}
            4 semaines{" "}
          </div>
          <div
            onClick={this.handleChangeRF5}
            className={this.state.f5 ? "vert button " : "button3 button "}
          >
            {" "}
            5 semaines{" "}
          </div>
          <div
            onClick={this.handleChangeRF6}
            className={this.state.f6 ? "vert button " : "button3 button "}
          >
            {" "}
            6 semaines{" "}
          </div>
          <div
            onClick={this.handleChangeRF7}
            className={this.state.f7 ? "vert button " : "button3 button "}
          >
            {" "}
            7 semaines{" "}
          </div>
          <div
            onClick={this.handleChangeRF8}
            className={this.state.f8 ? "vert button " : "button3 button "}
          >
            {" "}
            8 semaines{" "}
          </div>
          <div
            onClick={this.handleChangeRF9}
            className={this.state.f9 ? "vert button " : "button3 button "}
          >
            {" "}
            9 semaines{" "}
          </div>
          <div
            onClick={this.handleChangeRF10}
            className={this.state.f10 ? "vert button " : "button3 button "}
          >
            {" "}
            10 semaines{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          g. Pensez-vous que la qualité de réponse est impactée par une
          diminution du délai ? 0 (pas du tout) - 10 (totalement)
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRG0}
            className={
              this.state.g0 ? "vert button Right" : "button3 button Right"
            }
          >
            0
          </div>
          <div
            onClick={this.handleChangeRG1}
            className={this.state.g1 ? "vert button " : "button3 button "}
          >
            {" "}
            1{" "}
          </div>

          <div
            onClick={this.handleChangeRG2}
            className={this.state.g2 ? "vert button " : "button3 button "}
          >
            {" "}
            2{" "}
          </div>
          <div
            onClick={this.handleChangeRG3}
            className={this.state.g3 ? "vert button " : "button3 button "}
          >
            {" "}
            3{" "}
          </div>
          <div
            onClick={this.handleChangeRG4}
            className={this.state.g4 ? "vert button " : "button3 button "}
          >
            {" "}
            4{" "}
          </div>
          <div
            onClick={this.handleChangeRG5}
            className={this.state.g5 ? "vert button " : "button3 button "}
          >
            {" "}
            5{" "}
          </div>
          <div
            onClick={this.handleChangeRG6}
            className={this.state.g6 ? "vert button " : "button3 button "}
          >
            {" "}
            6{" "}
          </div>
          <div
            onClick={this.handleChangeRG7}
            className={this.state.g7 ? "vert button " : "button3 button "}
          >
            {" "}
            7{" "}
          </div>
          <div
            onClick={this.handleChangeRG8}
            className={this.state.g8 ? "vert button " : "button3 button "}
          >
            {" "}
            8{" "}
          </div>
          <div
            onClick={this.handleChangeRG9}
            className={this.state.g9 ? "vert button " : "button3 button "}
          >
            {" "}
            9{" "}
          </div>
          <div
            onClick={this.handleChangeRG10}
            className={this.state.g10 ? "vert button " : "button3 button "}
          >
            {" "}
            10{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          h. Est-ce qu’on vous communique l’enveloppe budgétaire ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRH1}
            className={
              this.state.h1 ? "vert button Right" : "button3 button Right"
            }
          >
            Rarement
          </div>
          <div
            onClick={this.handleChangeRH2}
            className={this.state.h2 ? "vert button " : "button3 button "}
          >
            {" "}
            De temps en temps
          </div>

          <div
            onClick={this.handleChangeRH3}
            className={this.state.h3 ? "vert button " : "button3 button "}
          >
            {" "}
            Souvent{" "}
          </div>
          <div
            onClick={this.handleChangeRH4}
            className={this.state.h4 ? "vert button " : "button3 button "}
          >
            {" "}
            Toujours{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          i. Avez-vous la possibilité de poser des questions, à l’oral, au
          Laboratoire, entre le brief et la recommandation ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRI1}
            className={
              this.state.i1 ? "vert button Right" : "button3 button Right"
            }
          >
            Rarement
          </div>
          <div
            onClick={this.handleChangeRI2}
            className={this.state.i2 ? "vert button " : "button3 button "}
          >
            {" "}
            De temps en temps
          </div>

          <div
            onClick={this.handleChangeRI3}
            className={this.state.i3 ? "vert button " : "button3 button "}
          >
            {" "}
            Souvent{" "}
          </div>
          <div
            onClick={this.handleChangeRI4}
            className={this.state.i4 ? "vert button " : "button3 button "}
          >
            {" "}
            Toujours{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          j. Vous arrive -t-il de gagner une compétition mais que le projet soit
          ensuite abandonné par le Laboratoire ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeRJ1}
            className={
              this.state.j1 ? "vert button Right" : "button3 button Right"
            }
          >
            Rarement
          </div>
          <div
            onClick={this.handleChangeRJ2}
            className={this.state.j2 ? "vert button " : "button3 button "}
          >
            {" "}
            De temps en temps
          </div>

          <div
            onClick={this.handleChangeRJ3}
            className={this.state.j3 ? "vert button " : "button3 button "}
          >
            {" "}
            Souvent{" "}
          </div>
          <div
            onClick={this.handleChangeRJ4}
            className={this.state.j4 ? "vert button " : "button3 button "}
          >
            {" "}
            Toujours{" "}
          </div>
        </div>

        <div className="containerBtnValider">
          <div className="button button10" onClick={this.handleSubmitQ2}>
            {" "}
            Valider
          </div>
        </div>
        {alertGOO}
        {alertBAD}
      </div>
    );
  }
}

export default Q2;
