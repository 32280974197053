import React, { Component } from "react";
import "./Q1.css";
import axios from "axios";

class Q1 extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      Q1: "",
      Precision: "",
      etat: 1,
      active: false,
      activeOui: false,

      active1: false,
      active2: false,
      active3: false,
      active4: false,
      active5: false,
      active6: false,

      R1: "",
      R2: "",
      R3: "",
      R4: "",
      R5: "",
      R6: "",

      GOOD: false,
      BAD: false,
      email_invite: "",
    };
  }
  componentWillMount() {}
  componentDidMount() {
    this.getUserbyToken();
    // console.log("token********" + this.props.token);
  }

  getUserbyToken = () => {
    setTimeout(() => {
      axios
        .post(
          `https://quizfnim.henri8.com/api/users/getUserByToken`,
          {
            token: this.props.token,
          } /*,
          {
            headers: {
              "Access-Control-Request-Headers":
                "X-Requested-With,X-Auth-Token,Content-Type,  Content-Length, Authorization",
            },
          }*/
        )
        .then((res) => {
          this.setState({
            email_invite: res.data.email_invite,
          });
        })
        .catch((err) => console.log(err));
    }, 200);
  };

  handleClick = () => {
    this.setState({ active: !this.state.active });
  };

  handleSubmitQ1 = () => {
    if (this.state.Q1 == "Non") {
      axios
        .put(`https://quizfnim.henri8.com/api/users/Quiz1`, {
          email_invite: this.state.email_invite,
          Q1R: this.state.Q1,
        })
        .then((res) => {
          setTimeout(() => {
            this.setState({ GOOD: false });
            this.props.parentCallback(this.state.etat);
          }, "3000");
        })
        .catch((err) => alert("Une erreur s'est produite"));

      this.setState({ GOOD: true });
    } else if (this.state.Q1 == "Oui") {
      if (this.state.Precision != "") {
        this.setState({ GOOD: true });

        axios
          .put(`https://quizfnim.henri8.com/api/users/Quiz1`, {
            email_invite: this.state.email_invite,
            Q1R: this.state.Q1,
            Q1RP: this.state.Precision,
          })
          .then((res) => {
            setTimeout(() => {
              this.setState({ GOOD: false });
              this.props.parentCallback(this.state.etat);
            }, "3000");
          })
          .catch((err) => alert("Une erreur s'est produite"));
      } else {
        this.setState({ BAD: true });

        setTimeout(() => {
          this.setState({ BAD: false });
        }, "5000");
      }
    } else {
      this.setState({ BAD: true });

      setTimeout(() => {
        this.setState({ BAD: false });
      }, "5000");
    }
  };

  handleChangeQ1Non = () => {
    this.setState({ Q1: "Non" });
    this.setState({ active: !this.state.active });
    this.setState({ activeOui: false });
  };

  handleChangeQ1Oui = () => {
    this.setState({ Q1: "Oui" });
    this.setState({ activeOui: !this.state.activeOui });
    this.setState({ active: false });
  };

  handleChangeP1 = () => {
    this.setState({ Precision: "Barrage secrétaire" });
    this.setState({ active1: true });
    this.setState({ active2: false });
    this.setState({ active3: false });
    this.setState({ active4: false });
    this.setState({ active5: false });
    this.setState({ active6: false });
  };

  handleChangeP2 = () => {
    this.setState({ Precision: "Ils ne répondent pas au téléphone" });
    this.setState({ active1: false });
    this.setState({ active2: true });
    this.setState({ active3: false });
    this.setState({ active4: false });
    this.setState({ active5: false });
    this.setState({ active6: false });
  };

  handleChangeP3 = () => {
    this.setState({ Precision: "Ils n’ont pas le temps de nous recevoir" });
    this.setState({ active1: false });
    this.setState({ active2: false });
    this.setState({ active3: true });
    this.setState({ active4: false });
    this.setState({ active5: false });
    this.setState({ active6: false });
  };

  handleChangeP4 = () => {
    this.setState({ Precision: "Ils ne lisent pas les e-mails envoyés" });
    this.setState({ active1: false });
    this.setState({ active2: false });
    this.setState({ active3: false });
    this.setState({ active4: true });
    this.setState({ active5: false });
    this.setState({ active6: false });
  };

  handleChangeP5 = () => {
    this.setState({
      Precision:
        "Ils ne répondent pas aux sollicitations sur les réseaux sociaux",
    });
    this.setState({ active1: false });
    this.setState({ active2: false });
    this.setState({ active3: false });
    this.setState({ active4: false });
    this.setState({ active5: true });
    this.setState({ active6: false });
  };

  handleChangeP6 = () => {
    this.setState({ Precision: "Autres" });
    this.setState({ active1: false });
    this.setState({ active2: false });
    this.setState({ active3: false });
    this.setState({ active4: false });
    this.setState({ active5: false });
    this.setState({ active6: true });
  };

  render() {
    var alertGOO = "";
    var alertBAD = "";

    if (this.state.GOOD == true) {
      alertGOO = (
        <div className="ContainerAlerte">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Vos réponses ont bien été prise en compte
          </div>
        </div>
      );
    }

    if (this.state.BAD == true) {
      alertBAD = (
        <div className="ContainerAlerteBad">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Veuillez répondre à toutes les questions avant de valider
          </div>
        </div>
      );
    }

    var templateQ1 = "";

    if (this.state.Q1 === "Oui") {
      templateQ1 = (
        <div className="itemQ">
          <div
            onClick={this.handleChangeP1}
            className={
              this.state.active1
                ? "vert button  Right"
                : "button3 button  Right"
            }
          >
            Barrage secrétaire{" "}
          </div>
          <div
            onClick={this.handleChangeP2}
            className={this.state.active2 ? "vert button  " : "button2 button"}
          >
            {" "}
            Ils ne répondent pas au téléphone
          </div>
          <div
            onClick={this.handleChangeP3}
            className={this.state.active3 ? "vert button  " : "button4 button"}
          >
            {" "}
            Ils n’ont pas le temps de nous recevoir
          </div>
          <div
            onClick={this.handleChangeP4}
            className={this.state.active4 ? "vert button  " : "button6 button"}
          >
            {" "}
            Ils ne lisent pas les e-mails envoyés{" "}
          </div>
          <div
            className="button button8"
            onClick={this.handleChangeP5}
            className={this.state.active5 ? "vert button  " : "button8 button"}
          >
            {" "}
            Ils ne répondent pas aux sollicitations sur les réseaux sociaux
          </div>
          <div
            className="button button10"
            onClick={this.handleChangeP6}
            className={this.state.active6 ? "vert button  " : "button10 button"}
          >
            {" "}
            Autres, précisez
          </div>
        </div>
      );
    }

    var templateAutre = "";

    if (this.state.Precision == "Autres" && this.state.Q1 != "Non") {
      templateAutre = (
        <div>
          <div className="itemQ">
            <textarea>Autres : </textarea>
          </div>
        </div>
      );
    }

    return (
      <div className="ContainerQ">
        <div className="TitreQuestion">1. Prospection </div>
        <div className="sousTitre">
          {" "}
          Eprouvez-vous des difficultés à prospecter auprès des laboratoires ?
        </div>

        <div className="itemQ">
          <div
            onClick={this.handleChangeQ1Non}
            className={
              this.state.active ? "vert button  Right" : "button5 button  Right"
            }
          >
            Non
          </div>
          <div
            onClick={this.handleChangeQ1Oui}
            className={
              this.state.activeOui
                ? "vert button  Right"
                : "button10 button  Right"
            }
          >
            {" "}
            Oui
          </div>
        </div>

        {templateQ1}
        {templateAutre}

        <div className="containerBtnValider">
          <div className="button button10" onClick={this.handleSubmitQ1}>
            {" "}
            Valider
          </div>
        </div>
        {alertGOO}
        {alertBAD}
      </div>
    );
  }
}

export default Q1;
