import React, { Component } from "react";
import "./Q4.css";
import axios from "axios";

class Q4 extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      Q4: "",
      etat: 4,

      ra: "",
      rb: "",
      rc1: "",
      rc2: "",
      rc3: "",
      rd: "",
      re: "",

      a0: false,
      a1: false,
      a2: false,
      a3: false,
      a4: false,
      a4: false,
      a5: false,
      a6: false,
      a7: false,
      a8: false,
      a9: false,
      a10: false,

      b1: false,
      b2: false,
      b3: false,

      c11: false,
      c12: false,
      c13: false,

      c21: false,
      c22: false,
      c23: false,

      c31: false,
      c22: false,
      c33: false,

      d1: false,
      d2: false,
      d3: false,
      d4: false,
      d4: false,
      d5: false,

      e1: false,
      e2: false,
      e3: false,
      e4: false,
      e4: false,

      GOOD: false,
      BAD: false,

      email_invite: "",
    };
  }
  componentWillMount() {}
  componentDidMount() {
    this.getUserbyToken();
  }
  getUserbyToken = () => {
    setTimeout(() => {
      axios
        .post(
          `https://quizfnim.henri8.com/api/users/getUserByToken`,
          {
            token: this.props.token,
          } /*,
          {
            headers: {
              "Access-Control-Request-Headers":
                "X-Requested-With,X-Auth-Token,Content-Type,  Content-Length, Authorization",
            },
          }*/
        )
        .then((res) => {
          this.setState({
            email_invite: res.data.email_invite,
          });
        })
        .catch((err) => console.log(err));
    }, 200);
  };

  //RF
  handleChangeRA0 = () => {
    this.setState({ ra: "0" });
    this.setState({ a0: true });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA1 = () => {
    this.setState({ ra: "1" });
    this.setState({ a0: false });
    this.setState({ a1: true });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA2 = () => {
    this.setState({ ra: "2" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: true });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA3 = () => {
    this.setState({ ra: "3" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: true });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA4 = () => {
    this.setState({ ra: "4" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: true });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA5 = () => {
    this.setState({ ra: "5" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: true });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA6 = () => {
    this.setState({ ra: "6" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: true });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA7 = () => {
    this.setState({ ra: "7" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: true });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA8 = () => {
    this.setState({ ra: "8" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: true });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA9 = () => {
    this.setState({ ra: "9" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: true });
    this.setState({ a10: false });
  };

  handleChangeRA10 = () => {
    this.setState({ ra: "10" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: true });
  };

  // RB

  handleChangeRB1 = () => {
    this.setState({ rb: "Jamais" });
    this.setState({ b1: true });
    this.setState({ b2: false });
    this.setState({ b3: false });
  };

  handleChangeRB2 = () => {
    this.setState({ rb: "Parfois" });
    this.setState({ b1: false });
    this.setState({ b2: true });
    this.setState({ b3: false });
  };

  handleChangeRB3 = () => {
    this.setState({ rb: "Toujours" });
    this.setState({ b1: false });
    this.setState({ b2: false });
    this.setState({ b3: true });
  };

  // C1

  handleChangeRC11 = () => {
    this.setState({ rc1: "Jamais" });
    this.setState({ c11: true });
    this.setState({ c12: false });
    this.setState({ c13: false });
  };

  handleChangeRC12 = () => {
    this.setState({ rc1: "Parfois" });
    this.setState({ c11: false });
    this.setState({ c12: true });
    this.setState({ c13: false });
  };

  handleChangeRC13 = () => {
    this.setState({ rc1: "Toujours" });
    this.setState({ c11: false });
    this.setState({ c12: false });
    this.setState({ c13: true });
  };

  // C2

  handleChangeRC21 = () => {
    this.setState({ rc2: "Jamais" });
    this.setState({ c21: true });
    this.setState({ c22: false });
    this.setState({ c23: false });
  };

  handleChangeRC22 = () => {
    this.setState({ rc2: "Parfois" });
    this.setState({ c21: false });
    this.setState({ c22: true });
    this.setState({ c23: false });
  };

  handleChangeRC23 = () => {
    this.setState({ rc2: "Toujours" });
    this.setState({ c21: false });
    this.setState({ c22: false });
    this.setState({ c23: true });
  };

  // C3

  handleChangeRC31 = () => {
    this.setState({ rc3: "Jamais" });
    this.setState({ c31: true });
    this.setState({ c32: false });
    this.setState({ c33: false });
  };

  handleChangeRC32 = () => {
    this.setState({ rc3: "Parfois" });
    this.setState({ c31: false });
    this.setState({ c32: true });
    this.setState({ c33: false });
  };

  handleChangeRC33 = () => {
    this.setState({ rc3: "Toujours" });
    this.setState({ c31: false });
    this.setState({ c32: false });
    this.setState({ c33: true });
  };

  //RD
  handleChangeRD1 = () => {
    this.setState({ rd: "Systématiquement" });
    this.setState({ d1: true });
    this.setState({ d2: false });
    this.setState({ d3: false });
    this.setState({ d4: false });
    this.setState({ d5: false });
  };

  handleChangeRD2 = () => {
    this.setState({ rd: "Rarement" });
    this.setState({ d1: false });
    this.setState({ d2: true });
    this.setState({ d3: false });
    this.setState({ d4: false });
    this.setState({ d5: false });
  };

  handleChangeRD3 = () => {
    this.setState({ rd: "De plus en plus difficilement" });
    this.setState({ d1: false });
    this.setState({ d2: false });
    this.setState({ d3: true });
    this.setState({ d4: false });
    this.setState({ d5: false });
  };
  handleChangeRD4 = () => {
    this.setState({ rd: "Parfois" });
    this.setState({ d1: false });
    this.setState({ d2: false });
    this.setState({ d3: false });
    this.setState({ d4: true });
    this.setState({ d5: false });
  };

  handleChangeRD5 = () => {
    this.setState({ rd: "Jamais" });
    this.setState({ d1: false });
    this.setState({ d2: false });
    this.setState({ d3: false });
    this.setState({ d4: false });
    this.setState({ d5: true });
  };

  //RE

  handleChangeRE1 = () => {
    this.setState({ re: "Fréquemment" });
    this.setState({ e1: true });
    this.setState({ e2: false });
    this.setState({ e3: false });
    this.setState({ e4: false });
  };

  handleChangeRE2 = () => {
    this.setState({ re: "De temps en temps" });
    this.setState({ e1: false });
    this.setState({ e2: true });
    this.setState({ e3: false });
    this.setState({ e4: false });
  };

  handleChangeRE3 = () => {
    this.setState({ re: "De plus en plus souvent" });
    this.setState({ e1: false });
    this.setState({ e2: false });
    this.setState({ e3: true });
    this.setState({ e4: false });
  };

  handleChangeRE4 = () => {
    this.setState({ re: "Jamais" });
    this.setState({ e1: false });
    this.setState({ e2: false });
    this.setState({ e3: false });
    this.setState({ e4: true });
  };

  handleSubmitQ4 = () => {
    if (
      (this.state.ra &&
        this.state.rb &&
        this.state.rc1 &&
        this.state.rc2 &&
        this.state.rc3 &&
        this.state.rd &&
        this.state.re) != ""
    ) {
      this.setState({ GOOD: true });

      //fonction

      axios
        .put(`https://quizfnim.henri8.com/api/users/Quiz4`, {
          email_invite: this.state.email_invite,
          Q4RA: this.state.ra,
          Q4RB: this.state.rb,
          Q4RC1: this.state.rc1,
          Q4RC2: this.state.rc2,
          Q4RC3: this.state.rc3,
          Q4RD: this.state.rd,
          Q4RE: this.state.re,
        })
        .then((res) => {
          setTimeout(() => {
            this.setState({ GOOD: false });
            this.props.parentCallback(this.state.etat);
          }, "3000");
        })
        .catch((err) => alert("Une erreur s'est produite"));
    } else {
      this.setState({ BAD: true });

      setTimeout(() => {
        this.setState({ BAD: false });
      }, "5000");
    }
  };

  render() {
    var alertGOO = "";
    var alertBAD = "";

    if (this.state.GOOD == true) {
      alertGOO = (
        <div className="ContainerAlerte">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Vos réponses ont bien été prise en compte
          </div>
        </div>
      );
    }

    if (this.state.BAD == true) {
      alertBAD = (
        <div className="ContainerAlerteBad">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Veuillez répondre à toutes les questions avant de valider
          </div>
        </div>
      );
    }

    return (
      <div className="ContainerQ">
        <div className="TitreQuestion">
          {" "}
          4. Relations de travail avec les équipes marketing, médicales et
          communication du Laboratoire
        </div>
        <div className="sousTitre">
          {" "}
          a. Quelle est, selon vous, le niveau de disponibilité des équipes pour
          valider les étapes, les budgets ? 0 (totalement insuffisant) - 10
          (Satisfaisant)
        </div>
        <div className="itemQ">
          <div
            onClick={this.handleChangeRA0}
            className={
              this.state.a0 ? "vert button  Right" : "button3 button  Right"
            }
          >
            0{" "}
          </div>
          <div
            onClick={this.handleChangeRA1}
            className={this.state.a1 ? "vert button  " : "button3 button  "}
          >
            {" "}
            1{" "}
          </div>
          <div
            onClick={this.handleChangeRA2}
            className={this.state.a2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            2{" "}
          </div>
          <div
            onClick={this.handleChangeRA3}
            className={this.state.a3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            3{" "}
          </div>
          <div
            onClick={this.handleChangeRA4}
            className={this.state.a4 ? "vert button  " : "button3 button  "}
          >
            {" "}
            4{" "}
          </div>
          <div
            onClick={this.handleChangeRA5}
            className={this.state.a5 ? "vert button  " : "button3 button  "}
          >
            {" "}
            5{" "}
          </div>
          <div
            onClick={this.handleChangeRA6}
            className={this.state.a6 ? "vert button  " : "button3 button  "}
          >
            {" "}
            6{" "}
          </div>
          <div
            onClick={this.handleChangeRA7}
            className={this.state.a7 ? "vert button  " : "button3 button  "}
          >
            {" "}
            7{" "}
          </div>
          <div
            onClick={this.handleChangeRA8}
            className={this.state.a8 ? "vert button  " : "button3 button  "}
          >
            {" "}
            8{" "}
          </div>
          <div
            onClick={this.handleChangeRA9}
            className={this.state.a9 ? "vert button  " : "button3 button  "}
          >
            {" "}
            9{" "}
          </div>
          <div
            onClick={this.handleChangeRA10}
            className={this.state.a10 ? "vert button  " : "button3 button  "}
          >
            {" "}
            10{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          b. Pensez-vous que les équipes clientes respectent les délais qu’ils
          vous imposent ?
        </div>
        <div className="itemQ">
          <div
            onClick={this.handleChangeRB1}
            className={
              this.state.b1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            {" "}
            Jamais{" "}
          </div>
          <div
            onClick={this.handleChangeRB2}
            className={this.state.b2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Parfois
          </div>
          <div
            onClick={this.handleChangeRB3}
            className={this.state.b3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Toujours{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          c. Votre client change-t-il de brief, d’objectif, de budget en cours
          de réalisation ?
        </div>

        <div className="itemQ">
          <span style={{ fontSize: "20px" }}> Brief :</span>{" "}
          <div
            onClick={this.handleChangeRC11}
            className={
              this.state.c11 ? "vert button  Right" : "button3 button  Right"
            }
          >
            Jamais
          </div>
          <div
            onClick={this.handleChangeRC12}
            className={this.state.c12 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Parfois{" "}
          </div>
          <div
            onClick={this.handleChangeRC13}
            className={this.state.c13 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Toujours{" "}
          </div>
        </div>
        <div className="itemQ">
          <span style={{ fontSize: "20px" }}> Objectif :</span>{" "}
          <div
            onClick={this.handleChangeRC21}
            className={
              this.state.c21 ? "vert button  Right" : "button3 button  Right"
            }
          >
            Jamais
          </div>
          <div
            onClick={this.handleChangeRC22}
            className={this.state.c22 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Parfois{" "}
          </div>
          <div
            onClick={this.handleChangeRC23}
            className={this.state.c23 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Toujours{" "}
          </div>
        </div>
        <div className="itemQ">
          <span style={{ fontSize: "20px" }}> Budget :</span>{" "}
          <div
            onClick={this.handleChangeRC31}
            className={
              this.state.c31 ? "vert button  Right" : "button3 button  Right"
            }
          >
            Jamais
          </div>
          <div
            onClick={this.handleChangeRC32}
            className={this.state.c32 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Parfois{" "}
          </div>
          <div
            onClick={this.handleChangeRC33}
            className={this.state.c33 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Toujours{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          d. Pensez-vous que le travail supplémentaire sur un projet est
          rémunéré à sa juste valeur ?
        </div>
        <div className="itemQ">
          <div
            onClick={this.handleChangeRD1}
            className={
              this.state.d1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            {" "}
            Systématiquement{" "}
          </div>
          <div
            onClick={this.handleChangeRD2}
            className={
              this.state.d2 ? "vert button  Right" : "button3 button  Right"
            }
          >
            {" "}
            Rarement
          </div>
          <div
            onClick={this.handleChangeRD3}
            className={this.state.d3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            De plus en plus difficilement{" "}
          </div>
          <div
            onClick={this.handleChangeRD4}
            className={this.state.d4 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Parfois{" "}
          </div>
          <div
            onClick={this.handleChangeRD5}
            className={this.state.d5 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Jamais{" "}
          </div>
        </div>

        <div className="sousTitre">
          {" "}
          E. Avez-vous la sensation que les projets sont abandonnés en cours de
          réalisation ?
        </div>
        <div className="itemQ">
          <div
            onClick={this.handleChangeRE1}
            className={
              this.state.e1 ? "vert button  Right" : "button3 button  Right"
            }
          >
            {" "}
            Fréquemment{" "}
          </div>
          <div
            onClick={this.handleChangeRE2}
            className={this.state.e2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            De temps en temps
          </div>
          <div
            onClick={this.handleChangeRE3}
            className={this.state.e3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            De plus en plus souvent
          </div>
          <div
            onClick={this.handleChangeRE4}
            className={this.state.e4 ? "vert button  " : "button3 button  "}
          >
            {" "}
            Jamais{" "}
          </div>
        </div>

        <div className="containerBtnValider">
          <div className="button button10" onClick={this.handleSubmitQ4}>
            {" "}
            Valider
          </div>
        </div>
        {alertGOO}
        {alertBAD}
      </div>
    );
  }
}

export default Q4;
