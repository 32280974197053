import React, { Component } from "react";
import "./Quiz.css";
import ProgressBar from "./Progressbar";
import { Firebase_Config } from "../../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";

class Quiz extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      page: 0,
      R1: false,
      R2: false,
      R3: false,
      compteurvote1: 0,
      compteurvote2: 0,
      compteurvote3: 0,
      Quiz1: [],
      Quiz2: [],
      Podiums: [],
      Marseille: 100 / 4,
      Lyon: 100 / 4,
      Paris: 100 / 2,
    };
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }

    this.dbQuiz1 = firebase.database().ref().child("PodiumQuiz1App");

    this.dbQuiz2 = firebase.database().ref().child("PodiumQuiz2App");
    this.dbPodiums = firebase.database().ref().child("PodiumFinal");

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleReponse1 = this.handleReponse1.bind(this);
    this.handleReponse2 = this.handleReponse2.bind(this);
    this.handleReponse3 = this.handleReponse3.bind(this);
  }

  handleChangePage(event) {
    this.setState({ page: event.target.value });
  }
  handleReponse1(event) {
    this.setState({ R1: true, R2: false, R3: false });
    console.log(this.state.R1);
  }
  handleReponse2(event) {
    this.setState({ R1: false, R2: true, R3: false });
  }
  handleReponse3(event) {
    this.setState({ R1: false, R2: false, R3: true });
  }
  componentWillMount() {
    this.db = firebase.database();

    this.db.ref("page").on("value", (snap) => {
      this.setState({ page: snap.val() });
    });
    this.db.ref("compteurvote1").on("value", (snap) => {
      this.setState({ compteurvote1: snap.val() });
    });
    this.db.ref("compteurvote2").on("value", (snap) => {
      this.setState({ compteurvote2: snap.val() });
    });
    this.db.ref("compteurvote3").on("value", (snap) => {
      this.setState({ compteurvote3: snap.val() });
    });
  }
  componentDidMount() {
    const prequestions = this.state.Podiums;
    this.dbPodiums.on("child_added", (snap) => {
      prequestions.push({
        id: snap.key,
        score: snap.val().score,
        date: snap.val().date,
        Fullname: snap.val().Fullname,
      });
      this.setState({ Podiums: prequestions });
    });
    /*
    const prequiz = this.state.Quiz1;
    const porcentageMarseille = 100 / 2;

    for (let i = 0; i < prequiz.length; i++) {
      console.log("boucle for");
      if (prequiz[i].ville == "Marseille") {
        console.log("Inside");
        console.log(prequiz[i].ville);
        porcentageMarseille++;
      }
    }

    this.setState({
      Marseille: porcentageMarseille,
    });*/
  }

  // pourcentage

  PorcentageQui1 = () => {
    const prequiz = this.state.Quiz1;
    console.log("quiz1");
    console.log(prequiz);
    const porcentageMarseille = 0;
    for (let i = 0; i < prequiz.length; i++) {
      console.log("boucle for");
      if (prequiz[i].ville == "Marseille") {
        console.log("Inside");
        console.log(prequiz[i].ville);
        porcentageMarseille++;
      }
    }

    this.setState({
      Marseille: porcentageMarseille / this.state.compteurvote1,
    });
  };

  // Fonction firebase

  GotoPage0 = () => {
    if (this.state.page !== 0) {
      firebase
        .database()
        .ref("page")
        .set(0)
        .then(this.setState({ page: 0 }));
    } else alert("error");
  };

  GotoPage1 = () => {
    if (this.state.page !== 1) {
      firebase
        .database()
        .ref("page")
        .set(1)
        .then(this.setState({ page: 1 }));
    } else alert("error");
  };

  GotoPage2 = () => {
    if (this.state.page !== 2) {
      firebase
        .database()
        .ref("page")
        .set(2)
        .then(this.setState({ page: 2 }));
    } else alert("error");
  };
  GotoPage3 = () => {
    if (this.state.page !== 3) {
      firebase
        .database()
        .ref("page")
        .set(3)
        .then(this.setState({ page: 3 }));
    } else alert("error");
  };

  GotoPage4 = () => {
    if (this.state.page !== 4) {
      firebase
        .database()
        .ref("page")
        .set(4)
        .then(this.setState({ page: 4 }));
    } else alert("error");
  };

  GotoPage5 = () => {
    if (this.state.page !== 5) {
      firebase
        .database()
        .ref("page")
        .set(5)
        .then(this.setState({ page: 5 }));
    } else alert("error");
  };
  GotoPage6 = () => {
    if (this.state.page !== 6) {
      firebase
        .database()
        .ref("page")
        .set(6)
        .then(this.setState({ page: 6 }));
    } else alert("error");
  };
  GotoPage7 = () => {
    if (this.state.page !== 7) {
      firebase
        .database()
        .ref("page")
        .set(7)
        .then(this.setState({ page: 7 }));
    } else alert("error");
  };

  render() {
    const DataQuiz1 = [
      {
        bgcolor: "#6a1b9a",
        completed: this.state.Marseille,
        ville: "Marseille",
      },
      { bgcolor: "#00695c", completed: this.state.Lyon, ville: "Lyon" },
      { bgcolor: "#ef6c00", completed: this.state.Paris, ville: "Paris" },
    ];

    const DataQuiz2 = [
      { bgcolor: "#00695c", completed: 75, ville: "Sydney" },
      { bgcolor: "#6a1b9a", completed: 25, ville: "Melbourne" },
      { bgcolor: "#ef6c00", completed: 25, ville: "Brisbane" },
    ];

    const DataQuiz3 = [
      { bgcolor: "#00695c", completed: 50, ville: "Rome" },
      { bgcolor: "#6a1b9a", completed: 25, ville: "Milan" },
      { bgcolor: "#ef6c00", completed: 25, ville: "Parma" },
    ];
    var templatePageAttenteFormateur = (
      <div className="Rap">
        <div className="ContainerAttenre">
          <div> Welcome to your RSV training room</div>
        </div>
        <div className="Next_Previous">
          <a href="#" class="previous" hidden>
            &laquo; Previous
          </a>
          <a class="next" onClick={this.GotoPage1}>
            Next &raquo;
          </a>
        </div>
      </div>
    );

    var templateQui1 = (
      <div className="ContainerQuiz">
        <div className="header">
          <div className="titre"> What is the Capital of France? </div>
          <div className="compteur">{this.state.compteurvote1}</div>
        </div>
        <div className="corps">
          <div className="ResponseBase">
            <div
              className={
                this.state.R1 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
            ></div>
            <div
              className={
                this.state.R1 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">1. Marseille</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.R2 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
            ></div>
            <div
              className={
                this.state.R2 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">2. Lyon</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.R3 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
            ></div>
            <div
              className={
                this.state.R3 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">3. Paris</div>
            </div>
          </div>
          <div className="Next_PreviousQuiz">
            <a class="previous" onClick={this.GotoPage0}>
              &laquo; Previous
            </a>
            <a class="next" onClick={this.GotoPage2}>
              Next &raquo;
            </a>
          </div>
          <div className="Qr_code">
            <img
              src="https://sanofi-quiz.henri8.com/IMG/qr-code%20QuizApp.png"
              className="Qr code"
            />
          </div>
        </div>
      </div>
    );
    var templateRV1 = (
      <div className="ContainerRV1">
        <div className="titre"> What is the Capital of France ? </div>

        {DataQuiz1.map(
          (item, idx) => (
            {
              item,
            },
            (
              <ProgressBar
                key={idx}
                bgcolor={item.bgcolor}
                completed={item.completed}
                ville={item.ville}
              />
            )
          )
        )}
        <div className="Next_Previous">
          <a class="previous" onClick={this.GotoPage1}>
            &laquo; Previous
          </a>
          <a class="next" onClick={this.GotoPage3}>
            Next &raquo;
          </a>
        </div>
      </div>
    );

    var templateQui2 = (
      <div className="ContainerQuiz">
        <div className="header">
          <div className="titre"> What is the Capital of Australia ? </div>
          <div className="compteur">{this.state.compteurvote2}</div>
        </div>

        <div className="corps">
          <div className="ResponseBase">
            <div
              className={
                this.state.R1 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
            ></div>
            <div
              className={
                this.state.R1 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">1. Sydney</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.R2 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
            ></div>
            <div
              className={
                this.state.R2 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">2. Melbourne</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.R3 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
            ></div>
            <div
              className={
                this.state.R3 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">3. Brisbane</div>
            </div>
          </div>
          <div className="Next_PreviousQuiz">
            <a class="previous" onClick={this.GotoPage2}>
              &laquo; Previous
            </a>
            <a class="next" onClick={this.GotoPage4}>
              Next &raquo;
            </a>
          </div>
          <div className="Qr_code">
            <img
              src="https://sanofi-quiz.henri8.com/IMG/qr-code%20QuizApp.png"
              className="Qr code"
            />
          </div>
        </div>
      </div>
    );

    var templateRV2 = (
      <div className="ContainerRV1">
        <div className="titre"> What is the Capital of Australia ? </div>

        {DataQuiz2.map(
          (item, idx) => (
            {
              item,
            },
            (
              <ProgressBar
                key={idx}
                bgcolor={item.bgcolor}
                completed={item.completed}
                ville={item.ville}
              />
            )
          )
        )}
        <div className="Next_Previous">
          <a class="previous" onClick={this.GotoPage3}>
            &laquo; Previous
          </a>
          <a className="next" onClick={this.GotoPage5}>
            Next &raquo;
          </a>
        </div>
      </div>
    );

    var templateQui3 = (
      <div className="ContainerQuiz">
        <div className="header">
          <div className="titre"> What is the Capital of Italy ? </div>
          <div className="compteur">{this.state.compteurvote3}</div>
        </div>

        <div className="corps">
          <div className="ResponseBase">
            <div
              className={
                this.state.R1 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
            ></div>
            <div
              className={
                this.state.R1 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">1. Rome</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.R2 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
            ></div>
            <div
              className={
                this.state.R2 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">2. Milan</div>
            </div>
          </div>
          <div className="ResponseBase">
            <div
              className={
                this.state.R3 === true ? "ResponseCheckGreen" : "ResponseCheck"
              }
            ></div>
            <div
              className={
                this.state.R3 === true ? "ResponseBlockGreen" : "ResponseBlock"
              }
            >
              <div className="ResponseBlockText">3. Parma</div>
            </div>
          </div>
          <div className="Next_PreviousQuiz">
            <a class="previous" onClick={this.GotoPage4}>
              &laquo; Previous
            </a>
            <a class="next" onClick={this.GotoPage6}>
              Next &raquo;
            </a>
          </div>
          <div className="Qr_code">
            <img
              src="https://sanofi-quiz.henri8.com/IMG/qr-code%20QuizApp.png"
              className="Qr code"
            />
          </div>
        </div>
      </div>
    );

    var templateRV3 = (
      <div className="ContainerRV1">
        <div className="titre"> What is the Capital of Italy ? </div>

        {DataQuiz3.map(
          (item, idx) => (
            {
              item,
            },
            (
              <ProgressBar
                key={idx}
                bgcolor={item.bgcolor}
                completed={item.completed}
                ville={item.ville}
              />
            )
          )
        )}
        <div className="Next_Previous">
          <a class="previous" onClick={this.GotoPage5}>
            &laquo; Previous
          </a>
          <a className="next" onClick={this.GotoPage7}>
            Next &raquo;
          </a>
        </div>
      </div>
    );

    var podium = (
      <div className="PodiumContainer">
        <div className="titre">Podium </div>

        <div className="corpsPodium">
          <table>
            <tr>
              <th>Firstname and Lastname</th>
              <th>Scores</th>
            </tr>
            {this.state.Podiums.sort((a, b) =>
              a.score > b.score ? -1 : 1
            ).map((item, idx) => (
              <tr>
                <td>{item.Fullname}</td>
                <td>{item.score}</td>
              </tr>
            ))}
          </table>
        </div>
        <div className="Next_Previous">
          <a class="previous" onClick={this.GotoPage6}>
            &laquo; Previous
          </a>
        </div>
      </div>
    );

    var template = "";

    if (this.state.page === 0) {
      template = templatePageAttenteFormateur;
    } else if (this.state.page === 1) {
      template = templateQui1;
    } else if (this.state.page === 2) {
      template = templateRV1;
    } else if (this.state.page === 3) {
      template = templateQui2;
    } else if (this.state.page === 4) {
      template = templateRV2;
    } else if (this.state.page === 5) {
      template = templateQui3;
    } else if (this.state.page === 6) {
      template = templateRV3;
    } else if (this.state.page === 7) {
      template = podium;
    } else {
      template = templatePageAttenteFormateur;
    }

    return <div className="QuizContainer">{template}</div>;
  }
}

export default Quiz;
