import React, { Component } from "react";
import "./Merci.css";

class Merci extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      Merci: "",
      etat: 3,
    };
  }
  componentWillMount() {}
  componentDidMount() {}

  render() {
    return (
      <div className="ContainerQ">
        <div className="TitreQuestion" style={{ textAlign: "center" }}>
          Merci pour votre implication.
        </div>
        <div
          className="sousTitre"
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          {" "}
          Vos réponses ont bien été prise en compte !
        </div>
        <div className="itemQ"></div>
      </div>
    );
  }
}

export default Merci;
