import React, { Component } from "react";
import "./LoginUtilisateur.css";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Firebase_Config } from "../../Config/config";
import * as firebase from "firebase/app";
import "firebase/database";

class LoginUtilisateur extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: "",
      password: "",
      sended: false,
      sommaire: false,
      nom: "",
      prenom: "",
      LocalUser: "",
    };
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(Firebase_Config);
    }

    this.handleChangeNom = this.handleChangeNom.bind(this);
    this.handleChangePrenom = this.handleChangePrenom.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    this.db = firebase.database();
  }
  componentDidMount() {
    console.log(localStorage.getItem("LocalUser"));
    if (localStorage.getItem("LocalUser") !== null) {
      this.props.history.push("/sommaireUsers");
    }
  }

  handleChangeNom(event) {
    this.setState({ nom: event.target.value });
    console.log(event.target.value);
  }

  handleChangePrenom(event) {
    this.setState({ prenom: event.target.value });
    console.log(event.target.value);
  }

  handleSubmit(event) {
    if (this.state.nom !== "" || this.state.prenom != "") {
      let uid = firebase.database().ref().child("votesQuizAppUsers").push().key;

      let d = new Date();
      let n = d.toLocaleString();
      let data = {
        id: uid,
        Fullname: this.state.nom + " " + this.state.prenom,
        date: n,
      };

      let updates = {};
      let updates2 = {};

      updates["/votesQuizAppUsers/" + uid] = data;
      firebase
        .database()
        .ref()
        .update(updates)
        .then(
          this.setState({
            etat: false,
            value: "",
          })
        );

      updates2["/votesQuizAppSavesUsers/" + uid] = data;
      firebase.database().ref().update(updates2);

      localStorage.setItem(
        "LocalUser",
        this.state.nom + " " + this.state.prenom
      );

      event.preventDefault();
      this.props.history.push("/sommaireUsers");
    } else {
      alert(
        "the lastname and first name fields cannot be empty The login and password fields cannot be empty"
      );
    }
  }

  render() {
    return (
      <div className="ContainerLoginUtilisateur">
        <form>
          <div className="form-group">
            <img
              src="https://zoom.henri8.com/IMG/Logo%20Sanofi.png"
              className="logoSanofi"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="Nom"
              placeholder="Lastname*"
              required
              value={this.state.nom}
              onChange={this.handleChangeNom}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="prenom"
              placeholder="Firstname*"
              required
              value={this.state.prenom}
              onChange={this.handleChangePrenom}
            />
          </div>
          <button
            type="button"
            class="btn btn-outline-success btn-block"
            onClick={this.handleSubmit}
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default LoginUtilisateur;
