import React, { Component } from "react";
import "./Q3.css";
import axios from "axios";

class Q3 extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      Q3: "",
      etat: 3,

      ra: "",

      a0: false,
      a1: false,
      a2: false,
      a3: false,
      a4: false,
      a4: false,
      a5: false,
      a6: false,
      a7: false,
      a8: false,
      a9: false,
      a10: false,

      GOOD: false,
      BAD: false,

      email_invite: "",
    };
  }
  componentWillMount() {}
  componentDidMount() {
    this.getUserbyToken();
  }
  getUserbyToken = () => {
    setTimeout(() => {
      axios
        .post(
          `https://quizfnim.henri8.com/api/users/getUserByToken`,
          {
            token: this.props.token,
          } /*,
          {
            headers: {
              "Access-Control-Request-Headers":
                "X-Requested-With,X-Auth-Token,Content-Type,  Content-Length, Authorization",
            },
          }*/
        )
        .then((res) => {
          this.setState({
            email_invite: res.data.email_invite,
          });
        })
        .catch((err) => console.log(err));
    }, 200);
  };

  //RA
  handleChangeRA0 = () => {
    this.setState({ ra: "0" });
    this.setState({ a0: true });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA1 = () => {
    this.setState({ ra: "1" });
    this.setState({ a0: false });
    this.setState({ a1: true });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA2 = () => {
    this.setState({ ra: "2" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: true });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA3 = () => {
    this.setState({ ra: "3" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: true });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA4 = () => {
    this.setState({ ra: "4" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: true });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA5 = () => {
    this.setState({ ra: "5" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: true });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA6 = () => {
    this.setState({ ra: "6" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: true });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA7 = () => {
    this.setState({ ra: "7" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: true });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA8 = () => {
    this.setState({ ra: "8" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: true });
    this.setState({ a9: false });
    this.setState({ a10: false });
  };

  handleChangeRA9 = () => {
    this.setState({ ra: "9" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: true });
    this.setState({ a10: false });
  };

  handleChangeRA10 = () => {
    this.setState({ ra: "10" });
    this.setState({ a0: false });
    this.setState({ a1: false });
    this.setState({ a2: false });
    this.setState({ a3: false });
    this.setState({ a4: false });
    this.setState({ a5: false });
    this.setState({ a6: false });
    this.setState({ a7: false });
    this.setState({ a8: false });
    this.setState({ a9: false });
    this.setState({ a10: true });
  };

  handleSubmitQ3 = () => {
    if (this.state.ra != "") {
      this.setState({ GOOD: true });

      //début foncion

      axios
        .put(`https://quizfnim.henri8.com/api/users/Quiz3`, {
          email_invite: this.state.email_invite,
          Q3R: this.state.ra,
        })
        .then((res) => {
          setTimeout(() => {
            this.setState({ GOOD: false });
            this.props.parentCallback(this.state.etat);
          }, "3000");
        })
        .catch((err) => alert("Une erreur s'est produite"));
    } else {
      this.setState({ BAD: true });

      setTimeout(() => {
        this.setState({ BAD: false });
      }, "5000");
    }
  };

  render() {
    var alertGOO = "";
    var alertBAD = "";

    if (this.state.GOOD == true) {
      alertGOO = (
        <div className="ContainerAlerte">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Vos réponses ont bien été prise en compte
          </div>
        </div>
      );
    }

    if (this.state.BAD == true) {
      alertBAD = (
        <div className="ContainerAlerteBad">
          <div className="titleAlerte"> Enquête La FNIM</div>
          <div className="descriptionAlerte">
            {" "}
            Veuillez répondre à toutes les questions avant de valider
          </div>
        </div>
      );
    }

    return (
      <div className="ContainerQ">
        <div className="TitreQuestion">3. Négociations budget </div>
        <div className="sousTitre">
          {" "}
          Avez-vous le sentiment que les budgets sont négociés plus fortement à
          la baisse actuellement ? 0 (absolument pas) - 10 (systématiquement)
        </div>
        <div className="itemQ">
          <div
            onClick={this.handleChangeRA0}
            className={
              this.state.a0 ? "vert button  Right" : "button3 button  Right"
            }
          >
            0{" "}
          </div>
          <div
            onClick={this.handleChangeRA1}
            className={this.state.a1 ? "vert button  " : "button3 button  "}
          >
            {" "}
            1{" "}
          </div>
          <div
            onClick={this.handleChangeRA2}
            className={this.state.a2 ? "vert button  " : "button3 button  "}
          >
            {" "}
            2{" "}
          </div>
          <div
            onClick={this.handleChangeRA3}
            className={this.state.a3 ? "vert button  " : "button3 button  "}
          >
            {" "}
            3{" "}
          </div>
          <div
            onClick={this.handleChangeRA4}
            className={this.state.a4 ? "vert button  " : "button3 button  "}
          >
            {" "}
            4{" "}
          </div>
          <div
            onClick={this.handleChangeRA5}
            className={this.state.a5 ? "vert button  " : "button3 button  "}
          >
            {" "}
            5{" "}
          </div>
          <div
            onClick={this.handleChangeRA6}
            className={this.state.a6 ? "vert button  " : "button3 button  "}
          >
            {" "}
            6{" "}
          </div>
          <div
            onClick={this.handleChangeRA7}
            className={this.state.a7 ? "vert button  " : "button3 button  "}
          >
            {" "}
            7{" "}
          </div>
          <div
            onClick={this.handleChangeRA8}
            className={this.state.a8 ? "vert button  " : "button3 button  "}
          >
            {" "}
            8{" "}
          </div>
          <div
            onClick={this.handleChangeRA9}
            className={this.state.a9 ? "vert button  " : "button3 button  "}
          >
            {" "}
            9{" "}
          </div>
          <div
            onClick={this.handleChangeRA10}
            className={this.state.a10 ? "vert button  " : "button3 button  "}
          >
            {" "}
            10{" "}
          </div>
        </div>

        <div className="containerBtnValider">
          <div className="button button10" onClick={this.handleSubmitQ3}>
            {" "}
            Valider
          </div>
        </div>
        {alertGOO}
        {alertBAD}
      </div>
    );
  }
}

export default Q3;
